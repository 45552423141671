import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'
// @ts-ignore
import AppCheckboxInput from '@/components/UI/atoms/AppCheckboxInput.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()
localVue.use(Vuex)

const props = {
    value: false,
    id: 'toto',
    labelKey: '',
    disabled: false
}
describe('components/UI/atoms/AppCheckboxInput.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppCheckboxInput, {
            ...basicMountWrapper,
            localVue,
            propsData: props
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const appCheckboxInputDiv = wrapper.find(
            '[data-testid="app-checkbox-input"'
        )
        expect(appCheckboxInputDiv.exists()).toBeTruthy()

        const appCheckboxInput = wrapper.find('[data-testid="checkbox-toto"')
        expect(appCheckboxInput.exists()).toBeTruthy()
    })

    it('can emits on change', async () => {
        await wrapper.vm.onChange({
            target: {
                checked: false
            }
        })
        expect(wrapper.emitted('change')).toBeTruthy()
        expect(wrapper.emitted('change')[0]).toEqual([false])

        await wrapper.vm.onChange({
            target: {
                checked: true
            }
        })
        expect(wrapper.emitted('change')).toBeTruthy()
        expect(wrapper.emitted('change')[1]).toEqual([true])
    })
})

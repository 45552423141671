export default function ({ $auth, route, store, redirect }) {
    const isLogin = /^(\/login)(.)*/.test(route.path)
    const isPasswordReset = /^(\/password-reset)(.)*/.test(route.path)
    if (!isLogin && !isPasswordReset) {
        const hasTokenValid = $auth?.strategy?.token?.status()?.valid()
        const hasRefreshToken = $auth?.strategy?.refreshToken?.status()?.valid()
        const logout = () =>
            store
                .dispatch('session/logout')
                .finally(() => redirect('/login#session-expired'))
        if (!hasTokenValid) {
            if (!hasRefreshToken) {
                $auth.$storage.setUniversal('redirect', route.path)
                return logout()
            }
            return $auth
                .refreshTokens()
                .then(() => {})
                .catch(() => logout())
        }
    }
}

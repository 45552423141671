import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'

// @ts-ignore
import AppCheckbox from '@/components/UI/AppCheckbox.vue'

// Mocks
import { i18n } from '~/test/mocks/i18n.mock'
import mobileFilterModule from '~/test/mocks/store/mobileFilter.mock'

const localVue = createLocalVue()
localVue.use(Vuex)
const modules = {
    mobileFilter: mobileFilterModule
}
const store = new Vuex.Store({
    modules
})

const mocks = {
    $t: i18n.$t,
    $tc: i18n.$tc,
    $emit: jest.fn()
}

describe('components/UI/AppCheckbox.vue', () => {
    let wrapper: any

    beforeAll(() => {
        wrapper = shallowMount(AppCheckbox, {
            localVue,
            store,
            mocks
        })
    })

    afterEach(() => {
        jest.clearAllMocks()
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="app-checkbox"]')
        expect(component.exists()).toBeTruthy()
    })

    it('should emit value on update', () => {
        const spyStoreCommit = jest.spyOn(wrapper.vm.$store, 'commit')
        const uid = wrapper.vm._uid
        expect(mocks.$emit).not.toHaveBeenCalled()
        wrapper.vm.update(true)
        expect(mocks.$emit).toHaveBeenCalledWith('input', true)
        expect(mocks.$emit).toHaveBeenCalledWith('change', true)
        expect(spyStoreCommit).toHaveBeenCalledWith(
            'mobileFilter/updateFilter',
            { id: uid, value: true }
        )
    })

    it('should call update on same id', () => {
        const newValue = 'test'
        const updateSpy = jest.spyOn(wrapper.vm, 'update')
        expect(updateSpy).not.toHaveBeenCalled()
        wrapper.vm.updateFilter({
            id: 'unknown',
            value: newValue
        })
        expect(updateSpy).not.toHaveBeenCalled()
        wrapper.vm.updateFilter({
            id: wrapper.vm._uid,
            value: newValue
        })
        expect(updateSpy).toHaveBeenCalledWith(newValue)
    })

    it('should call update with checked', () => {
        const evt = {
            target: {
                checked: true
            }
        }
        const updateSpy = jest.spyOn(wrapper.vm, 'update')
        expect(updateSpy).not.toHaveBeenCalled()
        wrapper.vm.changeValue(evt)
        expect(updateSpy).toHaveBeenCalledWith(evt.target.checked)
    })

    it('can return label', async () => {
        expect(wrapper.vm.label).toBe('enable')

        await wrapper.setProps({
            value: true
        })

        expect(wrapper.vm.label).toBe('disable')

        await wrapper.setProps({
            value: false
        })

        expect(wrapper.vm.label).toBe('enable')
    })
})


import Vue from 'vue'
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import ChargemapModal from '~/components/UI/CMUI/ChargemapModal.vue'
import ImageNewsletterStep1 from '~/components/UI/images/newsletter/ImageNewsletterStep1.vue'
import ImageNewsletterAccepted from '~/components/UI/images/newsletter/ImageNewsletterAccepted.vue'
import ImageNewsletterDelayed from '~/components/UI/images/newsletter/ImageNewsletterDelayed.vue'
import { NEWSLETTER_STEP } from '~/interfaces/other'

export default Vue.extend({
    name: 'NewsletterSubscriptionModal',
    components: {
        ChargemapModal,
        ImageNewsletterStep1,
        ImageNewsletterAccepted,
        ImageNewsletterDelayed
    },
    data() {
        return {
            step: NEWSLETTER_STEP.STEP_1,
            NEWSLETTER_STEP,
            isLoading: false
        }
    },
    computed: {
        ...mapGetters({
            user: 'session/getUser'
        }),
        textTitle(): string {
            switch (this.step) {
                case NEWSLETTER_STEP.ACCEPTED:
                    return 'menu.newsletter.subscribed_confirmed'
                case NEWSLETTER_STEP.DELAYED:
                    return 'menu.newsletter.text_delayed'
                default:
                    return 'menu.newsletter.step_1_title'
            }
        },
        image(): string {
            switch (this.step) {
                case NEWSLETTER_STEP.ACCEPTED:
                    return 'ImageNewsletterAccepted'
                case NEWSLETTER_STEP.DELAYED:
                    return 'ImageNewsletterDelayed'
                default:
                    return 'ImageNewsletterStep1'
            }
        },
        currentLocale(): string {
            return this.$i18n.locale || 'en'
        },
        businessLink() {
            return this.$config.BUSINESS_BLOG_URL
        },
        link(): string {
            switch (this.currentLocale) {
                case 'fr':
                    return `${this.businessLink}/politique-de-confidentialite/`

                case 'de':
                    return `${this.businessLink}/de/datenschutzrichtlinie/`
                default:
                    return `${this.businessLink}/en/privacy-policy/`
            }
        }
    },
    methods: {
        updateModalIsVisible(value: boolean) {
            this.$emit('updateModalIsVisible', value)
        },
        accept() {
            this.isLoading = true
            return this.$store
                .dispatch('session/subscribedUserToNewsletter')
                .then(async () => {
                    this.step = NEWSLETTER_STEP.ACCEPTED
                    const newUser = {
                        ...this.user,
                        isSubscribedToNewsletter: true
                    }
                    await (this as any).$auth.setUser(newUser)
                    await (this as any).$auth.$storage.setUniversal(
                        'user',
                        newUser
                    )
                })
                .catch(() => {
                    this.$toast?.global.error({
                        title: this.$tc('errors.oops'),
                        message: ''
                    })
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        discard() {
            this.step = NEWSLETTER_STEP.DELAYED
            Cookies.set('delayed-newsletter', 'true', {
                expires: 365
            })
        },
        close() {
            this.$emit('updateModalIsVisible', false)
        }
    }
})

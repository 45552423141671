import { ExpenseReport, ExpenseReportJSON } from '@/interfaces/expenseReport'
import { CallForFundAndExpenseReportsFilter } from '~/interfaces/filters'

const state = () => ({
    expenseReports: [],
    expenseReportsCount: null as number | null
})

const getters = {
    getExpenseReports(state: any) {
        return state.expenseReports
    },
    getExpenseReportsCount(state: any) {
        return state.expenseReportsCount
    }
}

const mutations = {
    setExpenseReports(state: any, expenseReports: ExpenseReport[]) {
        state.expenseReports = expenseReports
    },
    setExpenseReportsCount(state: any, count: number) {
        state.expenseReportsCount = count
    }
}

const actions = {
    fetchExpenseReports(
        this: any,
        { commit }: { commit: any },
        payload: {
            idOrga: number | undefined
            idBU: number | undefined
            filters: CallForFundAndExpenseReportsFilter
        }
    ) {
        const { filters } = payload
        const { pages, minStartDate, state, keyword } = filters
        const { idOrga, idBU } = payload
        const offset = (pages.index - 1) * pages.perPage || 0
        const limit = pages.perPage || 20
        const params = {
            offset,
            limit,
            min_start_date: minStartDate || null,
            state: state || null,
            keyword
        }
        let url = `refundyapi`
        if (idOrga) {
            url += `/organizations/${idOrga}`
        }
        if (idBU) {
            url += `/business-units/${idBU}`
        }
        url += `/expense_reports`

        return this.$axios
            .get(url, { params })
            .then(
                (res: {
                    headers: {
                        'x-total-count': string
                    }
                    data: {
                        items: ExpenseReportJSON
                    }
                }) => {
                    const result = this.$toCamel(res.data)
                    commit('setExpenseReports', result.items)
                    const count = parseInt(res.headers['x-total-count'], 10)
                    commit('setExpenseReportsCount', count)
                    return result
                }
            )
            .catch((e: any) => {
                throw e
            })
    }
}

export default {
    namespaced: true as true,
    state,
    getters,
    mutations,
    actions
}

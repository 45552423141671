import { CostCenter, CostCentersJSON } from '~/interfaces/businessunit'
import { axiosWrapper } from '~/utilitary/storeWrapperVuex'

const actions = {
    getCostCenters(
        this: any,
        _: any,
        payload: { idOrga: number; idBU: number; filters: any }
    ) {
        const { index, perPage, search } = payload.filters
        const offset = (index - 1) * perPage || (0 as number)
        const limit = perPage || (20 as number)
        const keyword = search || null
        const params = {
            offset,
            limit,
            keyword
        }

        const { idOrga, idBU } = payload
        return this.$axios
            .$get(
                `/cloudapi/organizations/${idOrga}/business-units/${idBU}/cost-centers`,
                { params }
            )
            .then((res: CostCentersJSON) => this.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    },
    postCostCenter(
        this: any,
        _: any,
        payload: { idOrga: number; idBU: number; costCenterName: string }
    ) {
        const { idOrga, idBU, costCenterName } = payload
        return this.$axios
            .$post(
                `/cloudapi/organizations/${idOrga}/business-units/${idBU}/cost-centers`,
                { name: costCenterName }
            )
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    },
    updateCostCenter(
        this: any,
        _: any,
        payload: { idOrga: number; idBU: number; costCenter: CostCenter }
    ) {
        const { idOrga, idBU, costCenter } = payload
        return this.$axios
            .$put(
                `/cloudapi/organizations/${idOrga}/business-units/${idBU}/cost-centers/${costCenter.id}`,
                { name: costCenter.name }
            )
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    },
    deleteCostCenter(
        this: any,
        _: any,
        payload: { idOrga: number; idBU: number; costCenter: CostCenter }
    ) {
        const { idOrga, idBU, costCenter } = payload
        return this.$axios
            .$delete(
                `/cloudapi/organizations/${idOrga}/business-units/${idBU}/cost-centers/${costCenter.id}`
            )
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    },
    import(_: any, payload: { attachment: any; idOrga: number; idBU: number }) {
        const { attachment, idOrga, idBU } = payload
        const url = `cloudapi/organizations/${idOrga}/business-units/${idBU}/cost-centers/batch`
        const formData = new FormData()
        formData.append('cost-centers', attachment)
        return new Promise((resolve, reject) => {
            axiosWrapper
                .bind(this)('post', url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res: { imported: number; ignored: number }) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }
}
export default {
    namespaced: true as true,
    actions
}

import { DateTime } from 'luxon'
import { Bill, ExportType } from '@/interfaces/bill'
import { BillFilter } from '@/interfaces/filters'

const state = () => ({
    bills: [],
    billsCount: null as number | null
})

const getters = {
    getBills(state: any) {
        return state.bills
    },
    getBillsCount(state: any) {
        return state.billsCount
    }
}

const mutations = {
    setBills(state: any, bills: Bill[]) {
        state.bills = bills
    },
    setBillsCount(state: any, count: number) {
        state.billsCount = count
    }
}

const actions = {
    fetchBills(
        vuexContext: any,
        payload: {
            idOrga: string | undefined
            idBU: string | undefined
            filters: BillFilter
        }
    ) {
        const { keyword, minStartDate, state, pages } = payload.filters
        const { index, perPage } = pages
        const date = minStartDate
            ? DateTime.fromISO(minStartDate).toString()
            : null
        const { idOrga, idBU } = payload
        const offset = (index - 1) * perPage || (0 as number)
        const limit = perPage || (20 as number)
        const params = {
            offset,
            limit,
            keyword,
            min_start_date: date,
            state,
            order_by: 'date,desc'
        }

        let url = '/cloudapi'
        if (idOrga) {
            url += `/organizations/${idOrga}`
        }
        if (idBU) {
            url += `/business-units/${idBU}`
        }
        url += '/invoices'
        return (this as any).$axios
            .$get(url, { params })
            .then((res: any) => {
                const result = (this as any).$toCamel(res)
                vuexContext.commit('setBills', result.invoices)
                vuexContext.commit('setBillsCount', result.count)
                return result
            })
            .catch((e: any) => {
                throw e
            })
    },
    fetchBillExport(
        _: any,
        payload: {
            idOrga: string | undefined
            idBU: string | undefined
            invoiceNo: string | undefined
            type: ExportType | undefined
        }
    ) {
        const { idOrga, idBU, invoiceNo, type } = payload

        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/invoices/${invoiceNo}/export?file_type=${type}`
        const call = (this as any).$axios
            .get(url)
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
        return call
    }
}

export default {
    namespaced: true as true,
    state,
    getters,
    mutations,
    actions
}

import {
    Collaborator,
    RefundBankAccountStatus
} from '../interfaces/collaborator'
import { FilterList } from '@/interfaces/filters'
import { axiosWrapper } from '~/utilitary/storeWrapperVuex'
import { convertDate } from './charge'

const actions = {
    hasUniqueEmail(
        vuexContext: any,
        payload: {
            idOrga: number
            idBU: number
            email: string
            idCollaborator: number | null
        }
    ) {
        const { idOrga, idBU, email, idCollaborator } = payload
        const filters = {
            searchField: '',
            email,
            pages: {
                index: 1,
                perPage: 1,
                totalItems: 0
            }
        }
        const payloadCollaborators = {
            idOrga,
            idBU,
            filters
        }
        return vuexContext
            .dispatch('getCollaborators', payloadCollaborators)
            .then((res: any) => {
                const collaborators = (this as any).$toCamel(res)
                if (collaborators.count > 0) {
                    return collaborators.collaborators[0].id === idCollaborator
                }
                return true
            })
            .catch(() => false)
    },
    getCollaborators(
        this: any,
        _: any,
        payload: { idOrga: number; idBU: number; filters: FilterList }
    ) {
        const { idOrga, idBU, filters } = payload

        const offset =
            (filters.pages.index - 1) * filters.pages.perPage || (0 as number)

        const limit = filters.pages.perPage || (20 as number)
        const keyword = filters.searchField || (null as null | string)
        const email = filters.email || (null as null | string)
        const order_by = filters.orderBy || (null as null | string)
        const hasRefundBankAccountStatus =
            filters.hasRefundBankAccountStatus || (null as null | boolean)

        let params = {
            offset,
            limit,
            keyword,
            email,
            order_by,
            has_business_pass: filters.hasBusinessPass,
            has_domestic_zone: filters.hasDomesticZone,
            min_date_created: undefined as string | undefined | null,
            max_date_created: undefined as string | undefined | null,
            has_refund_bank_account_status: hasRefundBankAccountStatus
        }

        if (filters.dateRange) {
            const { minStartDate, maxStartDate } = convertDate(
                filters.dateRange[0],
                filters.dateRange[1]
            )
            if (minStartDate) {
                params = { ...params, min_date_created: minStartDate }
            }
            if (maxStartDate) {
                params = { ...params, max_date_created: maxStartDate }
            }
        }

        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/collaborators`

        return this.$axios
            .$get(url, { params })
            .then((res: any) => this.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    },
    getCollaborator(
        _: any,
        payload: { idOrga: number; idBU: number; idCollaborator: number }
    ) {
        const { idOrga, idBU, idCollaborator } = payload
        return new Promise((resolve, reject) => {
            const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/collaborators/${idCollaborator}`
            ;(this as any).$axios
                .$get(url)
                .then((res: any) => {
                    const collaborator = (this as any).$toCamel(res)
                    resolve(collaborator)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    createCollaborator(
        _: any,
        payload: { collaborator: any; idOrga: number; idBU: number }
    ) {
        const { collaborator, idOrga, idBU } = payload

        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$post(
                    `/cloudapi/organizations/${idOrga}/business-units/${idBU}/collaborators`,
                    collaborator
                )
                .then((res: any) => {
                    const collab = (this as any).$toCamel(res)
                    resolve(collab)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    updateCollaborator(
        _: any,
        payload: { collaborator: any; idOrga: number; idBU: number }
    ) {
        const { collaborator, idOrga, idBU } = payload

        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$put(
                    `/cloudapi/organizations/${idOrga}/business-units/${idBU}/collaborators/${collaborator.id}`,
                    { ...collaborator, id: undefined }
                )
                .then((res: any) => {
                    const collab = (this as any).$toCamel(res)
                    resolve(collab)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    deleteCollaborator(
        _: any,
        payload: { idCollaborator: number; idOrga: number; idBU: number }
    ) {
        const { idCollaborator, idOrga, idBU } = payload
        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/collaborators/${idCollaborator}`
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$delete(url)
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(new Error(e))
                })
        })
    },
    sendInvitCommunityAssociation(
        _: any,
        payload: { idCollaborator: number; idOrga: number; idBU: number }
    ) {
        const { idCollaborator, idOrga, idBU } = payload
        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/collaborators/${idCollaborator}/community-association`
        return new Promise((resolve, reject) => {
            axiosWrapper
                .bind(this)('post', url)
                .then((res: Collaborator) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    updateRefundBankAccountStatusAndIban(
        _: any,
        payload: {
            idCollaborator: number
            idOrga: number
            idBU: number
            refundBankAccountStatus?: RefundBankAccountStatus
            iban?: string
            bic?: string
        }
    ) {
        const {
            idCollaborator,
            idOrga,
            idBU,
            refundBankAccountStatus,
            bic,
            iban
        } = payload
        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/collaborators/${idCollaborator}`
        const refundBankAccount = iban
            ? {
                  iban,
                  bic: bic === '' ? null : bic
              }
            : null

        const params = {
            refund_bank_account_status: refundBankAccountStatus
        } as {
            refund_bank_account_status: RefundBankAccountStatus
            refund_bank_account?: {
                iban: string
                bic: string | null | undefined
            }
        }

        if (refundBankAccount) params.refund_bank_account = refundBankAccount

        return new Promise((resolve, reject) => {
            axiosWrapper
                .bind(this)('patch', url, params)
                .then((res: Collaborator) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    import(
        _: any,
        payload: {
            attachment: any
            idOrga: number
            idBU: number
            shouldUpdate?: boolean
        }
    ) {
        const { attachment, idOrga, idBU, shouldUpdate } = payload
        let url = `cloudapi/organizations/${idOrga}/business-units/${idBU}/collaborators/batch`

        if (shouldUpdate) {
            url += `?should_update=${shouldUpdate}`
        }

        const formData = new FormData()
        formData.append('collaborators', attachment)
        return new Promise((resolve, reject) => {
            axiosWrapper
                .bind(this)('post', url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res: { imported: number; ignored: number }) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }
}

export default {
    namespaced: true as true,
    actions
}


import Vue from 'vue'
import VueI18n from 'vue-i18n'
import IconChevronLeft from '@/components/UI/icons/IconChevronLeft.vue'
import IconChevronRight from '@/components/UI/icons/IconChevronRight.vue'

export default Vue.extend({
    name: 'ChargemapPagination',
    components: { IconChevronLeft, IconChevronRight },
    props: {
        value: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            default: 0
        },
        range: {
            type: Number,
            default: 1
        },
        hasMobileControls: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        pathLoco: {
            type: String,
            default: 'inputs.pagination.'
        },
        isInModal: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
    computed: {
        start(this: any): number {
            if (this.total === 0) return 0
            return (this.value - 1) * this.perPage + 1
        },
        end(this: any): number {
            return this.total < this.value * this.perPage
                ? this.total
                : this.value * this.perPage
        },
        maxPage(this: any): number {
            return this.total % this.perPage === 0
                ? this.total / this.perPage
                : (this.total - (this.total % this.perPage)) / this.perPage + 1
        },
        rangeBefore(this: any): number[] {
            const rangeBefore = []
            for (let index = this.range; index > 0; index -= 1) {
                const prevValue = this.value - index
                if (prevValue > 0) {
                    rangeBefore.push(prevValue)
                }
            }
            return rangeBefore
        },
        rangeAfter(this: any): number[] {
            const rangeAfter = []
            for (let index = 1; index <= this.range; index += 1) {
                const nextValue = this.value + index
                if (nextValue < this.maxPage) {
                    rangeAfter.push(nextValue)
                }
            }
            return rangeAfter
        },
        paginationText(this: any): VueI18n.TranslateResult {
            let textReplaced = this.$t(`${this.pathLoco}text`)
            textReplaced = textReplaced.replace(
                '%s',
                `<span class="font-medium">${this.start.toString()}</span>`
            )
            textReplaced = textReplaced.replace(
                '%e',
                `<span class="font-medium">${this.end.toString()}</span>`
            )
            textReplaced = textReplaced.replace(
                '%t',
                `<span class="font-medium">${this.$n(
                    this.total,
                    'decimal'
                )}</span>`
            )
            return textReplaced
        }
    },
    methods: {
        async goTo(this: any, index: number) {
            if (!this.isInModal) {
                const { path, hash, query } = this.$route
                await this.$router.push({
                    path,
                    hash,
                    query: { ...query, index: index.toString() }
                })
            }
            this.$emit('input', index)
        }
    }
})

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"fixed flex h-full items-center p-6 justify-center inset-0 bg-gray-600 bg-opacity-20 z-50",attrs:{"data-testid":"chargemap-modal"}},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickedOutside),expression:"clickedOutside"}],staticClass:"relative bg-white rounded-lg shadow-md overflow-auto max-h-[95vh]",class:[
            _vm.size === 'sm' && 'w-2/3 md:w-2/6',
            _vm.size === 'md' && 'w-11/12 md:w-4/6 max-w-3xl',
            _vm.size === 'lg' && 'w-11/12 md:w-10/12 max-w-6xl',
            _vm.size === 'xl' && 'w-full h-full',
            !_vm.noPadding && 'p-6',
            _vm.noScroll ? 'overflow-visible' : 'overflow-auto'
        ],on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"flex flex-col justify-between gap-4 h-full",class:[
                !_vm.noPadding && 'p-1',
                _vm.noScroll ? 'overflow-visible' : 'overflow-auto'
            ]},[(_vm.$slots.title)?_c('div',{staticClass:"text-lg font-bold leading-6 tracking-light text-gray-900",class:[_vm.textCenter && 'text-center']},[_vm._t("title")],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-col gap-6 h-full",class:[_vm.textCenter && 'text-center']},[_vm._t("default")],2),_vm._v(" "),(_vm.$slots.actions)?_c('div',{staticClass:"flex justify-end space-x-2"},[_vm._t("actions")],2):_vm._e()]),_vm._v(" "),(_vm.showX)?_c('div',{staticClass:"absolute right-4 top-4 z-20 cursor-pointer h-9 w-9 bg-white p-1 rounded-lg hover:bg-gray-100 flex items-center justify-center",attrs:{"data-testid":"chargemap-modal-x"},on:{"click":_vm.closeModal}},[_c('IconX')],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
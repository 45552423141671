const convertToPrefilledCustomer = (billingInfo) => {
    const {
        email,
        billingAddress: {
            company,
            postalCode,
            city,
            countryCode,
            addressLine1,
            addressLine2
        }
    } = billingInfo
    return {
        email,
        company_name: company,
        postal_code: postalCode,
        country_code: countryCode,
        city,
        address_line1: addressLine1,
        address_line2: addressLine2 || ''
    }
}

const generateTokenSessionGoCardless = (tokenAuth, error) => {
    const TOKEN_SIZE = 100
    if (tokenAuth && tokenAuth.length >= TOKEN_SIZE) {
        return tokenAuth.slice(0, TOKEN_SIZE)
    }
    return error({
        statusCode: 401,
        message: 'errors.authenticate_payment'
    })
}

const goCardlessHeader = (token, error) => {
    if (!token || token === 'undefined') {
        error({
            statusCode: 500,
            message: 'errors.configuration_issues'
        })
    }
    return {
        headers: {
            'GoCardless-Version': '2015-07-06',
            Authorization: `Bearer ${token}`
        }
    }
}

const fetchGoCardlessUrl = (
    { store, route, $config, error },
    gocardlessApi,
    header,
    sessionToken
) =>
    store
        .dispatch('businessunit/getBillingAccount', {
            idOrga: route.params.idOrga,
            idBU: route.params.idBU
        })
        .then((res) => {
            if (!$config.baseURL) {
                error({
                    statusCode: 500,
                    message: 'errors.configuration_issues'
                })
            }
            if (!res) return
            const successRedirectUrl = $config.baseURL + route.path
            const prefilledCustomer = convertToPrefilledCustomer(res)
            const params = {
                redirect_flows: {
                    description: 'Chargemap',
                    session_token: sessionToken,
                    success_redirect_url: successRedirectUrl,
                    prefilled_customer: prefilledCustomer
                }
            }
            return gocardlessApi
                .$post('/gocardless/redirect_flows', params, header)
                .then((x) => {
                    const url = x.redirect_flows.redirect_url
                    if (!url) {
                        throw new Error('Redirect flow url is missing')
                    }
                    return store.commit('payment/setGoCardlessUrl', url)
                })
        })
        .catch((err) => {
            if (err?.response?.status !== 404) {
                return err
            }
        })

const fetchMandateId = (
    { store },
    gocardlessApi,
    header,
    redirectFlowId,
    sessionToken
) => {
    const url = `/gocardless/redirect_flows/${redirectFlowId}/actions/complete`
    const payload = {
        data: {
            session_token: sessionToken
        }
    }
    return gocardlessApi.$post(url, payload, header).then((res) => {
        const mandateId = res.redirect_flows.links.mandate
        if (!mandateId) {
            throw new Error('Mandate id is missing')
        }
        return store.commit('payment/setMandateId', mandateId)
    })
}

export default function ({
    store,
    route,
    $config,
    $auth,
    $axios,
    error,
    $sentry
}) {
    if (process.server) {
        const authToken = $auth.strategy.token.get()
        const gocardlessApi = $axios.create()
        const sessionToken = generateTokenSessionGoCardless(authToken, error)

        const token = $config.GO_CARDLESS_TOKEN
        const header = goCardlessHeader(token, error)

        const { redirect_flow_id: redirectFlowId } = route.query
        return fetchGoCardlessUrl(
            { store, route, $config, error, $sentry },
            gocardlessApi,
            header,
            sessionToken
        )
            .then(() => {
                if (redirectFlowId) {
                    return fetchMandateId(
                        { store },
                        gocardlessApi,
                        header,
                        redirectFlowId,
                        sessionToken
                    )
                }
                return null
            })
            .catch((e) => {
                try {
                    $sentry.captureException(e)
                } catch (exception) {}
                const mandateIdIsAlreadyFetched = e.message.includes('422')
                if (!mandateIdIsAlreadyFetched) {
                    error({
                        statusCode: 500,
                        message: 'errors.payment_error'
                    })
                }
            })
    }
}

import { DateTime } from 'luxon'
import { Authorization, AuthorizationDTO } from '~/interfaces/authorization'
import { ChargeFilter } from '~/interfaces/filters'

// eslint-disable-next-line import/prefer-default-export
export const convertDate = (dateStartRaw?: string, dateEndRaw?: string) => {
    if (!dateStartRaw) {
        return {}
    }
    const dateStart = DateTime.fromISO(dateStartRaw)
    const minStartDate = dateStart.toISO()
    if (!dateEndRaw) {
        return { min_start_date: minStartDate }
    }
    const dateEnd = DateTime.fromISO(dateEndRaw).plus({ days: 1 })
    const maxStartDate = dateEnd.toISO()
    return { min_start_date: minStartDate, max_start_date: maxStartDate }
}
// eslint-disable-next-line import/prefer-default-export
export const actions = {
    fetchAuthorizations(
        this: any,
        _: any,
        payload: { idBU: number; filters: ChargeFilter }
    ): Promise<{ count: number; authorizations: Authorization[] }> {
        const { idBU, filters } = payload
        const { dateRange, pages, searchField, isAccepted, isKnown } = filters
        const offset = (pages.index - 1) * pages.perPage || (0 as number)
        const limit = pages.perPage || (20 as number)
        const keyword = searchField || (null as null | string)
        const accepted = isAccepted
        // eslint-disable-next-line camelcase
        const known_pass = isKnown
        let params: any = { offset, limit, keyword, known_pass, accepted }
        if (dateRange) {
            params = {
                ...params,
                ...convertDate(dateRange[0], dateRange[1])
            }
        }
        const url = `/supervisionapi/business-units/${idBU}/authorizations`
        return this.$axios
            .get(url, { params })
            .then((response: any) => {
                const resDTO = response.data.items as AuthorizationDTO[]
                const count = parseInt(response.headers['x-total-count'], 10)
                const authorizations = (this as any).$toCamel(
                    resDTO
                ) as Authorization[]
                return {
                    count,
                    authorizations
                }
            })
            .catch((e: any) => {
                throw e
            })
    }
}

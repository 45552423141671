import { DeliveryPoint, DeliveryPointDTO } from '~/interfaces/deliveryPoint'
import { Chargebox, ChargeboxJSON, evseStatuses } from '~/interfaces/chargebox'

export enum TypeZone {
    PRIVATE = 'PRIVATE',
    DOMESTIC = 'DOMESTIC',
    CONNECTED_CABLE = 'CONNECTED_CABLE'
}

export interface ZoneCommon {
    id: number
    type?: TypeZone
    zoneType?: TypeZone
    name: string
}

export interface GPSCoordinates {
    latitude: number | null
    longitude: number | null
}

export interface Zone extends ZoneCommon {
    businessUnitId?: number
    address: string
    postalCode: string
    city: string
    countryCode: string
    chargeboxes?: Chargebox[]
    chargeboxesCount?: number
    completeAddress?: string
    deliveryPoint?: DeliveryPoint | null
    deliveryPointId?: number | null
    notifyCollaborator: boolean
    gpsCoordinates?: GPSCoordinates
}

export interface ZoneJSON {
    id: number
    type: TypeZone
    business_unit_id: number
    name?: string
    address: string
    postal_code: string
    city: string
    country_code: string
    chargeboxes: ChargeboxJSON[]
    delivery_point?: DeliveryPointDTO | null
    gps_coordinates?: GPSCoordinates
}

export interface ZoneEventStatus {
    chargebox: {
        id: number
        isConnected: boolean | null
        lastCall: string | null
    }
    evse: { id: number; status: evseStatuses }
    connector: { id: number; status: evseStatuses }
}

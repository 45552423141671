export enum TypeSlideCarousel {
    PREV = 'prev',
    NEXT = 'next'
}

export enum TypeImport {
    COLLABORATOR = 'collaborator',
    VEHICLE = 'vehicle',
    COSTCENTER = 'costCenter'
}

export enum NEWSLETTER_STEP {
    STEP_1 = 'STEP_1',
    ACCEPTED = 'ACCEPTED',
    DELAYED = 'DELAYED'
}

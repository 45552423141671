export enum LicenseType {
    PASS = 'PASS',
    CHARGEBOX = 'CHARGEBOX',
    SIM_CARD = 'SIM_CARD',
    CHARGEBOX_REBILLING = 'CHARGEBOX_REBILLING',
    CHARGEBOX_DOMESTIC = 'CHARGEBOX_DOMESTIC'
}

export type LicenseDetailDTO = {
    type: LicenseType
    actual_count: number
    max_count: number
    unitary_price: number
    total_renewal_price: number
}

export type LicenseDetail = {
    type: LicenseType
    actualCount: number
    maxCount: number
    unitaryPrice: number
    totalRenewalPrice: number
}
export type LicenseBUDTO = {
    business_unit_id: number
    business_unit_name: string
    total_renewal_price: number
    licenses: {
        PASS: LicenseDetailDTO
        CHARGEBOX: LicenseDetailDTO
        CHARGEBOXREBILLING: LicenseDetailDTO
        CHARGEBOXDOMESTIC: LicenseDetailDTO
        SIMCARD: LicenseDetailDTO
    }
}
export type LicenseBU = {
    businessUnitId: number
    businessUnitName: string
    totalRenewalPrice: number
    licenses: {
        PASS: LicenseDetail
        CHARGEBOX: LicenseDetail
        CHARGEBOXREBILLING: LicenseDetail
        CHARGEBOXDOMESTIC: LicenseDetail
        SIMCARD: LicenseDetail
    }
}

export type LicenseOrgaDTO = {
    total_renewal_price: number
    licenses: LicenseBUDTO[]
}
export type LicenseOrga = {
    totalRenewalPrice: number
    licenses: LicenseBU[]
}


import Vue from 'vue'
import { v4 } from 'uuid'
import { isString } from 'lodash'
import IconXCircle from '@/components/UI/icons/IconXCircle.vue'
import IconSearch from '@/components/UI/icons/input/IconSearch.vue'
import IconEye from '@/components/UI/icons/cloud/IconEye.vue'
import IconEyeClosed from '@/components/UI/icons/cloud/IconEyeClosed.vue'

export default Vue.extend({
    name: 'AppInput', // AppInput because Input is reserved
    components: {
        IconXCircle,
        IconEye,
        IconEyeClosed,
        IconSearch
    },
    inheritAttrs: false,
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        isValid: {
            type: Boolean,
            default: true
        },
        unit: {
            type: String,
            default: ''
        },
        errorMessage: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        typeInput: {
            type: String,
            default: 'text'
        },
        isSearch: {
            type: Boolean,
            default: false
        },
        isPassword: {
            type: Boolean,
            default: false
        },
        isFocus: {
            type: Boolean,
            default: false
        },
        noX: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: undefined
        }
    },
    data: () => ({
        canvas: undefined as any,
        isRevealed: false,
        id: v4()
    }),
    computed: {
        hasValue(): boolean {
            return !!this.value
        },
        canShowEyeIcon(): boolean {
            return !!(this.isPassword && this.isValid && this.value)
        },
        canShowSearchIcon(): boolean {
            return !!(this.isSearch && (!this.hasValue || this.noX))
        },
        canShowXCircleIcon(): boolean {
            return !!(
                (!this.isPassword || !this.isValid) &&
                !this.noX &&
                !this.disabled
            )
        },
        getTypeInput(): string {
            return this.isPassword && !this.isRevealed
                ? 'password'
                : this.typeInput
        },
        getSizeInputText(): number {
            let size = 1
            if (this.canvas) {
                const ctx = this.canvas.getContext('2d')
                if (ctx) {
                    ctx.font = 'inherit'
                    const text = ctx.measureText(this.value)
                    size = text.width
                }
            }
            return size * 0.1 // Text size in REM
        },
        calculatePositionLeft(): number {
            let totalLeft = 0.75 // Default space is 0.75rem
            const spaceBetweenTextAndSuffix = 0.25
            totalLeft += this.getSizeInputText + spaceBetweenTextAndSuffix
            return totalLeft
        }
    },
    watch: {
        isFocus(val: boolean) {
            if (val && this.$refs?.AppInput) {
                // @ts-ignore
                this.$refs.AppInput.focus()
            }
        }
    },
    mounted() {
        if (typeof document !== 'undefined') {
            this.canvas = this.$refs.canvasTextInput
        }

        if (this.isFocus && this.$refs?.AppInput) {
            // @ts-ignore
            this.$refs.AppInput.focus()
        }
    },
    methods: {
        handleReveal(this: any) {
            this.isRevealed = !this.isRevealed
        },
        updateValue(value: string | HTMLInputElement) {
            let val = value
            if (!isString(value)) {
                val = (value as HTMLInputElement).value
            }
            this.$emit('input', val)
        }
    }
})

import mobileFilterStore from '~/store/mobileFilter'
import { Filters } from '~/interfaces/filters'

const { getters } = mobileFilterStore

const mobileFilterModule = {
    namespaced: true,
    state: {
        filters: [
            {
                id: 1,
                name: 'label',
                value: null,
                options: []
            }
        ] as Filters[],
        showBottomSheet: false as Boolean,
        showSearchBar: false as Boolean,
        hasSearch: false as Boolean,
        toUpdate: false as Boolean
    },
    getters: {
        getFilters(state: any) {
            return state.filters
        },
        getFilter(state: any, filter: any) {
            return state.filters.find((f: any) => f.id === filter.id)
        },
        getShowBottomSheet(state: any) {
            return state.showBottomSheet
        },
        getShowSearchBar(state: any) {
            return state.showSearchBar
        },
        getHasSearch(state: any) {
            return state.hasSearch
        },
        getToUpdate(state: any) {
            return state.toUpdate
        }
    },
    mutations: {
        setFilters(state: any, filters: any) {
            state.filters = filters
        },
        setShowBottomSheet(state: any, showBottomSheet: boolean) {
            state.showBottomSheet = showBottomSheet
        },
        setShowSearchBar(state: any, showSearchBar: boolean) {
            state.showSearchBar = showSearchBar
        },
        setToUpdate(state: any, toUpdate: boolean) {
            state.toUpdate = toUpdate
        },
        addFilter(state: any, filter: any) {
            state.filters.push(filter)
        },
        removeFilter(state: any, idFilter: number) {
            state.filters = state.filters.filter((f: any) => f.id !== idFilter)
        },
        resetFilter(state: any) {
            state.filters.forEach((filter: any) => {
                filter.value = null
            })
        },
        updateFilter(state: any, filter: any) {
            const filterMatch = getters.getFilter(state, filter)
            if (filterMatch) {
                filterMatch.value = filter.value
            }
        },
        setHasSearch(state: any, hasSearch: boolean) {
            state.hasSearch = hasSearch
        }
    }
}

export default mobileFilterModule


import Vue from 'vue'
import IconCopy from '~/components/UI/icons/IconCopy.vue'
import ChargemapModal from '~/components/UI/CMUI/ChargemapModal.vue'

export default Vue.extend({
    name: 'TeslaNetworkModal',
    components: {
        IconCopy,
        ChargemapModal
    },
    computed: {
        link() {
            const lang = this.$i18n.locale || 'en'
            return lang === 'fr'
                ? `${this.$config.SUPPORT_URL}/l/fr/article/ti97wwy8to`
                : `${this.$config.SUPPORT_URL}/l/en/article/ti97wwy8to`
        }
    },
    methods: {
        updateModalIsVisible(value: boolean) {
            this.$emit('updateModalIsVisible', value)
        },
        reset() {
            this.updateModalIsVisible(false)
            this.$emit('closeAllTeslaModal')
        },
        copy() {
            navigator.clipboard.writeText(this.link)
        }
    }
})

import { Order } from '@/interfaces/order'
import { OrderState, PayloadFilters } from '~/interfaces/filters'

const state = () => ({
    orders: [],
    ordersCount: null as number | null,
    orderDetails: null as Order | null
})

const getters = {
    getOrders(state: any) {
        return state.orders
    },
    getOrdersCount(state: any) {
        return state.ordersCount
    },
    getOrderDetails(state: any): Order | null {
        return state.orderDetails
    }
}

const mutations = {
    setOrders(state: any, orders: Order[]) {
        state.orders = orders
    },
    setOrdersCount(state: any, count: number) {
        state.ordersCount = count
    },
    setOrderDetails(state: any, order: Order) {
        state.orderDetails = order
    }
}

const actions = {
    fetchOrders(
        this: any,
        vuexContext: any,
        payload: {
            idOrga: number | undefined
            idBU: number | undefined
            filters: PayloadFilters
        }
    ) {
        const { filters } = payload
        const { pages, state } = filters

        const { idOrga, idBU } = payload
        const offset = (pages.index - 1) * pages.perPage || (0 as number)
        const limit = pages.perPage || (20 as number)
        const params = {
            offset,
            limit,
            cancelled: false,
            shipped: null as boolean | null,
            keyword: filters.keyword || null
        }

        if (state) {
            params.shipped = state === OrderState.PROCESSED
        }

        let url = '/fleetapi'
        if (idBU) {
            url += `/business-units/${idBU}`
        } else if (idOrga) {
            url += `/organizations/${idOrga}`
        }
        url += '/orders'

        return this.$axios
            .get(url, { params })
            .then((res: any) => {
                const result = this.$toCamel(res.data.items)
                vuexContext.commit('setOrders', result)
                vuexContext.commit(
                    'setOrdersCount',
                    parseInt(res?.headers['x-total-count'], 10)
                )
                return result
            })
            .catch((e: any) => {
                throw e
            })
    },
    fetchOrderDetails(
        this: any,
        context: any,
        payload: {
            idOrga: number | undefined
            idBU: number | undefined
            orderId: string
        }
    ) {
        const { idOrga, idBU, orderId } = payload

        let url = '/fleetapi'
        if (idBU) {
            url += `/business-units/${idBU}`
        } else if (idOrga) {
            url += `/organizations/${idOrga}`
        }
        url += `/orders/${orderId}`
        return this.$axios
            .$get(url)
            .then((res: any) => {
                const result = this.$toCamel(res)
                context.commit('setOrderDetails', result)
                return result
            })
            .catch((e: any) => {
                const defaultError = e.message
                if (e?.response?.status === 400) {
                    const reason = e.response.data?.reason ?? defaultError
                    throw new Error(reason)
                } else {
                    throw new Error(defaultError)
                }
            })
    },
    getNumberPass(_: any, invoiceNumber: string) {
        const url = `/fleetapi/orders/${invoiceNumber}`
        return (this as any).$axios
            .$get(url)
            .then((res: any) => (this as any).$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    },
    resetOrder(_: any, invoiceNumber: string) {
        const url = '/fleetapi/orders/reset'
        return (this as any).$axios
            .$post(url, { invoice_no: invoiceNumber })
            .then((res: any) => (this as any).$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    },
    postOrderPass(
        _: any,
        payload: { invoiceNumber: string; passNumber: string }
    ) {
        const { invoiceNumber, passNumber } = payload
        const params = { invoice_no: invoiceNumber, serial_no: passNumber }
        const url = '/fleetapi/orders/passes'
        return (this as any).$axios
            .$post(url, params)
            .then((res: any) => (this as any).$toCamel(res))
            .catch((e: any) => {
                const defaultError = 'Sorry, something went wrong'
                if (e?.response?.status === 400) {
                    const reason = e.response.data?.reason ?? defaultError
                    throw new Error(reason)
                } else {
                    throw new Error(defaultError)
                }
            })
    },
    patchOrder(
        _: any,
        payload: { invoiceNumber: string; trackingNumber: string }
    ) {
        const { invoiceNumber, trackingNumber } = payload
        const url = `/fleetapi/orders/${invoiceNumber}`
        return (this as any).$axios
            .$patch(url, { tracking_number: trackingNumber })
            .then((res: any) => (this as any).$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    }
}

export default {
    namespaced: true as true,
    state,
    getters,
    mutations,
    actions
}

import { cloneDeep } from 'lodash'
import { PaymentInfo, PaymentType, RouteInfo } from '~/interfaces/payment'

const convertToPayload = (paymentInfo: PaymentInfo) => {
    const { paymentSelected, paymentId } = paymentInfo.payment
    switch (paymentSelected) {
        case PaymentType.CREDIT:
            return {
                type: 'STRIPE_CB',
                stripe_payment_mean_id: paymentId
            }
        case PaymentType.DEBIT:
            return {
                type: 'DEBIT',
                gocardless_mandate_id: paymentId
            }
        case PaymentType.BANK_TRANSFER:
            return {
                type: 'BANK_TRANSFER'
            }
        default:
            return null
    }
}

const state = () => ({
    mandateId: null as string | null,
    goCardlessUrl: null as string | null,
    billingAccount: {
        email: '',
        lang: 'FR',
        type: 'COMPANY',
        billingAddress: {
            firstName: '',
            name: '',
            company: '',
            addressLine1: '',
            addressLine2: '',
            postalCode: '',
            city: '',
            countryCode: 'FR',
            countryName: 'France',
            phone: '',
            vatNumber: ''
        }
    }
})

const getters = {
    getMandateId(state: any) {
        return state.mandateId
    },
    getBillingAccount(state: any) {
        return state.billingAccount
    },
    getGoCardlessUrl(state: any) {
        return state.goCardlessUrl
    }
}
const mutations = {
    setMandateId(state: any, mandateId: string | null) {
        state.mandateId = mandateId
    },
    setBillingAccount(state: any, billingAccount: any) {
        state.billingAccount = cloneDeep(billingAccount)
    },
    setGoCardlessUrl(state: any, goCardlessUrl: string) {
        state.goCardlessUrl = goCardlessUrl
    }
}

const actions = {
    getSetupIntent(_: any, infoPayment: RouteInfo) {
        const { idOrga, idBU } = infoPayment
        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/payment-mean/stripe/setup-intent`
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$get(url)
                .then((res: any) => {
                    const clienSecret = res.client_secret
                    if (!clienSecret) {
                        reject(new Error('Mandate id is missing'))
                    }
                    resolve(clienSecret)
                })
                .catch((e: any) => {
                    reject(e.response)
                })
        })
    },
    sendPayment(_: any, infoPayment: PaymentInfo) {
        const { idOrga, idBU } = infoPayment.routeInfo
        const payload = convertToPayload(infoPayment)
        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/payment-mean`
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$post(url, payload)
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e.response)
                })
        })
    },
    completeCredit(
        _: any,
        payload: { stripe: any; clientSecret: string; card: any }
    ) {
        return new Promise((resolve, reject) => {
            payload.stripe
                .confirmCardSetup(
                    payload.clientSecret,
                    {
                        payment_method: {
                            card: payload.card
                        }
                    },
                    { handleAction: false }
                )
                .then((result: any) => {
                    if (
                        !result ||
                        result.setupIntent?.payment_method === 'None'
                    ) {
                        reject(new Error('Error, payment not processed'))
                    }
                    if (result.error) {
                        const error = result.error.message || result.error
                        reject(error)
                    }
                    const paymentId = result.setupIntent.payment_method
                    resolve(paymentId)
                })
                .catch((e: any) => {
                    const error =
                        e.response?.message || 'Error, something went wrong'
                    reject(new Error(error))
                })
        })
    },
    deletePayment(
        this: any,
        _: any,
        payload: { idOrga: number; idBU: number }
    ) {
        const { idOrga, idBU } = payload
        return this.$axios
            .delete(
                `/cloudapi/organizations/${idOrga}/business-units/${idBU}/payment-mean/default`
            )
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    }
}

export default {
    namespaced: true as true,
    state,
    getters,
    mutations,
    actions
}

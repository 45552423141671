
import Vue from 'vue'
import IconX from '~/components/UI/icons/IconX.vue'

export default Vue.extend({
    name: 'ChargemapModal',
    components: {
        IconX
    },
    props: {
        size: {
            type: String,
            default: 'md',
            validator(value: string) {
                return ['sm', 'md', 'lg', 'xl'].includes(value)
            }
        },
        noPadding: {
            type: Boolean,
            default: true
        },
        textCenter: {
            type: Boolean,
            default: false
        },
        showX: {
            type: Boolean,
            default: false
        },
        noScroll: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        document.addEventListener('keyup', (event) =>
            this.closeModalWhenEscape(event)
        )
    },
    methods: {
        closeModal() {
            this.$emit('updateIsVisible', false)
        },
        closeModalWhenEscape(event: KeyboardEvent) {
            if (event.key === 'Escape') {
                this.closeModal()
            }
        },
        clickedOutside() {
            this.$emit('clickedOutside')
        }
    }
})

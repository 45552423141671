import { createLocalVue, mount } from '@vue/test-utils'
import * as Vuex from 'vuex'
// @ts-ignore
import AppFilter from '~/components/UI/AppFilter.vue'
import mobileFilterModule from '~/test/mocks/store/mobileFilter.mock'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()
localVue.use(Vuex)

const $emit = jest.fn()
const modules = {
    mobileFilter: mobileFilterModule
}
const store = new Vuex.Store({
    modules
})

describe('AppFilter.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = mount(AppFilter, {
            ...basicMountWrapper,
            localVue,
            propsData: {
                value: undefined,
                elements: [
                    {
                        id: 0,
                        translation: 'Filtrer par état',
                        value: undefined
                    },
                    {
                        id: 1,
                        translation: 'Tous les états',
                        value: null
                    }
                ]
            },
            store,
            mocks: {
                ...basicMountWrapper.mocks,
                $emit
            }
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        expect(wrapper.vm.value).toEqual(null)
        expect(wrapper.vm.label).toEqual(undefined)
        expect(wrapper.vm.elementsAvailable).toEqual([
            {
                id: 1,
                translation: 'Tous les états',
                value: null
            }
        ])
    })

    it('set elements available', async () => {
        await wrapper.setProps({
            elements: [
                {
                    id: 0,
                    translation: 'Filtrer par état',
                    value: undefined
                },
                {
                    id: 1,
                    translation: 'Tous les états',
                    value: null
                },
                {
                    id: 2,
                    translation: 'En attente',
                    value: null
                },
                {
                    id: 3,
                    translation: 'Réglé',
                    value: null
                }
            ]
        })
        expect(wrapper.vm.elementsAvailable).toEqual([
            {
                id: 1,
                translation: 'Tous les états',
                value: null
            },
            {
                id: 2,
                translation: 'En attente',
                value: null
            },
            {
                id: 3,
                translation: 'Réglé',
                value: null
            }
        ])
    })

    it('label error', async () => {
        await wrapper.setProps({
            value: 'test'
        })
        expect(wrapper.vm.label).toBe('Error not a value available')
        await wrapper.setProps({
            elements: []
        })
        expect(wrapper.vm.label).toBe('Error not a value available')
    })

    it('update', () => {
        const spyStoreCommit = jest.spyOn(wrapper.vm.$store, 'commit')
        wrapper.vm.update('test value')
        const uid = wrapper.vm._uid
        expect($emit).toHaveBeenCalledWith('input', 'test value')
        expect(spyStoreCommit).toHaveBeenCalledWith(
            'mobileFilter/updateFilter',
            { id: uid, value: 'test value' }
        )
    })

    it('updateFilter', () => {
        const uid = wrapper.vm._uid
        const badFilter = { id: 999, value: 'bad' }
        const filterValue = { id: uid, value: 'text' }
        const spyUpdate = jest.spyOn(wrapper.vm, 'update')
        wrapper.vm.updateFilter(badFilter)
        expect(spyUpdate).not.toHaveBeenCalled()
        wrapper.vm.updateFilter(filterValue)
        expect(spyUpdate).toHaveBeenCalledWith('text')
    })
})

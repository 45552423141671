
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'AppCheckbox',
    props: {
        value: {
            type: Boolean,
            default: undefined
        }
    },
    data() {
        return {
            inputId: 'checkbox'
        }
    },
    computed: {
        ...mapGetters({
            filters: 'mobileFilter/getFilters',
            showBottomSheet: 'mobileFilter/getShowBottomSheet'
        }),
        BSfilter(this: any) {
            const translation = this.value ? 'disable' : 'enable'
            return {
                id: this._uid,
                name: this.$slots.label
                    ? this.$slots.label[0].text
                    : this.$tc(translation),
                value: this.value,
                type: 'AppFilterCheckbox'
            }
        },
        label(): string {
            return this.value ? 'disable' : 'enable'
        }
    },
    watch: {
        filters: {
            handler(this: any, val) {
                const filter = val.find((f: any) => f.id === this._uid)
                if (this.showBottomSheet) {
                    this.updateFilter(filter)
                }
            },
            deep: true
        }
    },
    mounted(this: any) {
        this.inputId = this._uid
        this.$store.commit('mobileFilter/addFilter', this.BSfilter)
    },

    beforeDestroy(this: any) {
        this.$store.commit('mobileFilter/removeFilter', this._uid)
    },
    methods: {
        update(this: any, value: boolean) {
            this.$emit('input', value)
            this.$emit('change', value)
            this.$store.commit('mobileFilter/updateFilter', {
                id: this._uid,
                value
            })
        },
        updateFilter(this: any, filter: any) {
            if (filter.id === this._uid) {
                this.update(filter.value)
            }
        },
        changeValue(evt: any) {
            this.update(evt.target.checked)
        }
    }
})

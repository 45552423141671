const actions = {
    canAccessTo(
        vuexContext: any,
        route: {
            params: { idOrga: string | undefined; idBU: string | undefined }
        }
    ) {
        const { idOrga, idBU } = route.params
        if (!idOrga) {
            return true
        }
        const organizations =
            vuexContext.rootGetters['session/getOrganizationsSession']
        const organisation = organizations.filter(
            (orga: any) => parseInt(orga.id, 10) === parseInt(idOrga, 10)
        )
        const hasOrganization = organisation.length === 1
        if (!hasOrganization) {
            return false
        }
        if (!idBU) {
            return true
        }
        const bus = organisation[0].businessUnits
        const hasBus = bus.length > 0
        if (!hasBus) {
            return false
        }
        return (
            bus.filter((bu: any) => parseInt(bu.id, 10) === parseInt(idBU, 10))
                .length === 1
        )
    }
}

export default {
    actions
}

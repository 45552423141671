import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'
// @ts-ignore
import TimePicker from '@/components/UI/atoms/TimePicker.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()
localVue.use(Vuex)

const hours = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'
]

const minutes = ['00', '15', '30', '45']
const minutes10 = ['00', '10', '20', '30', '40', '50']
localVue.directive('click-outside', jest.fn())

describe('components/UI/atoms/TimePicker.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(TimePicker, {
            ...basicMountWrapper,
            localVue,
            propsData: {
                hour: '05',
                minute: '15',
                step: 15
            }
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const timePicker = wrapper.find('[data-testid="timepicker"')
        expect(timePicker.exists()).toBeTruthy()
    })

    describe('computed', () => {
        it('has correct hours array', () => {
            expect(wrapper.vm.listHours).toEqual(hours)
        })

        it('has correct minutes array', () => {
            expect(wrapper.vm.listMinutes).toEqual(minutes)
        })

        it('has correct minutes array with step 10', async () => {
            await wrapper.setProps({
                hour: '05',
                minute: '15',
                step: 10
            })
            expect(wrapper.vm.listMinutes).toEqual(minutes10)
        })
    })

    describe('methods', () => {
        it('setHour', () => {
            wrapper.vm.setHour('20')
            expect(wrapper.vm.editHour).toBeFalsy()
            expect(wrapper.vm.currentHour).toBe('20')
        })

        it('setMinute', () => {
            wrapper.vm.setMinute('45')
            expect(wrapper.vm.editMinute).toBeFalsy()
            expect(wrapper.vm.currentMinute).toBe('45')
        })
    })
})


import _ from 'lodash'
import { mapGetters } from 'vuex'
import IconSearch from '~/components/UI/icons/input/IconSearch.vue'
import IconClear from '~/components/UI/icons/input/IconClear.vue'

export default {
    name: 'AppSearch',
    components: { IconSearch, IconClear },
    inheritAttrs: false,
    props: {
        value: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: 'input-search'
        },
        name: {
            type: String,
            default: 'search'
        },
        type: {
            type: String,
            default: 'text'
        }
    },
    emits: ['input', 'change'],
    data() {
        return {
            search: this.value,
            error: '',
            showMobile: false
        }
    },
    computed: {
        ...mapGetters({
            showSearchBar: 'mobileFilter/getShowSearchBar'
        })
    },
    watch: {
        value() {
            this.search = this.value
        },
        showSearchBar(val) {
            this.toggleMobile(val)
        }
    },
    mounted() {
        if (this.$device && this.$device.isDesktop) {
            this.$refs.inputSearch?.focus()
        }
        this.$store.commit('mobileFilter/setHasSearch', true)
    },
    beforeDestroy() {
        this.$store.commit('mobileFilter/setHasSearch', false)
    },
    methods: {
        onInput() {
            if (this.controlInput()) {
                this.$emit('input', this.search)
            }
        },
        onChange() {
            if (this.controlInput()) {
                this.$emit('change', this.search)
            }
        },
        clear() {
            this.search = ''
            this.$emit('input', this.search)
            this.$emit('change', this.search)
        },
        controlInput() {
            this.error = ''
            if (
                this.type === 'email' &&
                !!this.search &&
                !this.validEmail(this.search)
            ) {
                this.error = this.$tc('email.invalid')
            }
            return this.error === ''
        },
        validEmail(email) {
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(email)
        },
        toggleMobile(force) {
            if (!_.isUndefined(force)) {
                this.showMobile = force
            } else {
                this.showMobile = !this.showMobile
            }
            if (this.showMobile) {
                this.$refs.inputSearch?.focus()
            }
        }
    }
}

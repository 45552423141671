import {
    LicenseBU,
    LicenseBUDTO,
    LicenseOrga,
    LicenseOrgaDTO,
    LicenseType
} from '~/interfaces/license'

const state = () => ({
    licenses: [] as LicenseOrga[],
    licensesBU: [] as LicenseBU[]
})

const getters = {
    getLicenses(state: any) {
        return state.licenses
    },
    getLicensesBU(state: any) {
        return state.licensesBU
    }
}

const mutations = {
    setLicenses(state: any, licenses: any) {
        state.licenses = licenses
    },
    setLicensesBU(state: any, licensesBU: any) {
        state.licensesBU = licensesBU
    }
}
const actions = {
    fetchLicensesBU(
        this: any,
        { commit }: { commit: any },
        payload: { idOrga: number; idBU: number }
    ) {
        const { $axios, $toCamel } = this
        const { idOrga, idBU } = payload
        return $axios
            .$get(
                `/flaggyapi/organizations/${idOrga}/business-units/${idBU}/licenses`
            )
            .then((licenseBUDTO: LicenseBUDTO) => {
                const licenseBU = $toCamel(licenseBUDTO)
                return commit('setLicensesBU', licenseBU)
            })
            .catch((e: any) => new Error(e.message))
    },
    fetchLicenses(this: any, { commit }: { commit: any }, idOrga: number) {
        const { $axios, $toCamel } = this
        return $axios
            .$get(`/flaggyapi/organizations/${idOrga}/licenses`)
            .then((licenseOrgaDTO: LicenseOrgaDTO) => {
                const licenseOrga = $toCamel(licenseOrgaDTO)
                return commit('setLicenses', licenseOrga)
            })
            .catch((e: any) => new Error(e.message))
    },
    orderChargebox(
        this: any,
        _: any,
        payload: {
            chargeboxLicenseNumber: number
            chargeboxRebillingLicenseNumber: number
            chargeboxDomesticLicenseNumber: number
            simCardLicenseNumber: number
            idBU: string
            collaborators: number[]
        }
    ) {
        const {
            chargeboxRebillingLicenseNumber,
            chargeboxDomesticLicenseNumber,
            chargeboxLicenseNumber,
            simCardLicenseNumber,
            idBU,
            collaborators
        } = payload

        const orderLicense = {
            licenses: {
                CHARGEBOX: chargeboxLicenseNumber || undefined,
                CHARGEBOX_REBILLING:
                    chargeboxRebillingLicenseNumber || undefined,
                SIM_CARD: simCardLicenseNumber || undefined,
                CHARGEBOX_DOMESTIC: chargeboxDomesticLicenseNumber || undefined
            },
            collaborators_ids: collaborators || []
        }

        return this.$axios
            .$post(
                `/supervisionapi/business-units/${idBU}/orders`,
                orderLicense
            )
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    },
    cancelLicense(
        this: any,
        _: any,
        payload: {
            totalLicenseToCancel: number | string
            licenseType: LicenseType
            idBU: string
        }
    ) {
        const { $axios } = this
        const { totalLicenseToCancel, licenseType, idBU } = payload
        let url = `fleetapi/business-units/${idBU}/licenses`
        if (licenseType !== LicenseType.PASS)
            url = `supervisionapi/business-units/${idBU}/licenses`

        const params = {
            license_type:
                licenseType !== LicenseType.PASS ? licenseType : undefined,
            licenses_count: parseInt(totalLicenseToCancel as string, 10)
        }
        return $axios.$delete(url, { data: params }).catch((e: any) => {
            throw e
        })
    }
}

export default {
    namespaced: true as true,
    state,
    getters,
    mutations,
    actions
}

import Vue from 'vue'

const eventTitle = {}

// eslint-disable-next-line @typescript-eslint/no-shadow
eventTitle.install = function (Vue) {
    Vue.prototype.$title = new Vue()
}

Vue.use(eventTitle)

import {
    BUCloudStatisticsJSON,
    BUCloudStatistics,
    BUSupervisionStatistics,
    BUSupervisionStatisticsJSON
} from '@/interfaces/statistics'

const actions = {
    fetchCloudStatistics(
        this: any,
        _: any,
        payload: { idOrga: number; idBU: number }
    ): Promise<BUCloudStatistics> {
        const { idOrga, idBU } = payload
        let url = `/cloudapi/organizations/${idOrga}`
        if (idBU) {
            url += `/business-units/${idBU}`
        }
        url += '/statistics'
        return this.$axios
            .$get(url)
            .then((res: BUCloudStatisticsJSON) => {
                const stats: BUCloudStatistics = (this as any).$toCamel(res)
                return stats
            })
            .catch((e: any) => {
                throw e
            })
    },
    fetchSupervisionStatistics(
        this: any,
        _: any,
        payload: { idOrga: number; idBU: number }
    ): Promise<BUSupervisionStatistics> {
        const { idOrga, idBU } = payload
        let url = `/supervisionapi/organizations/${idOrga}`
        if (idBU) {
            url = `/supervisionapi/business-units/${idBU}`
        }
        url += '/statistics'
        return this.$axios
            .$get(url)
            .then((res: BUSupervisionStatisticsJSON) => this.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    }
}

export default {
    namespaced: true as true,
    actions
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-inline items-center",attrs:{"role":"form","data-testid":"app-checkbox-input"}},[_c('input',{staticClass:"h-4 w-4 mr-2 align-top text-chargemap-600 border-gray-300 rounded focus:ring-0",class:[
            _vm.disabled
                ? 'bg-gray-300 cursor-not-allowed opacity-50'
                : 'bg-white cursor-pointer'
        ],attrs:{"id":_vm.id,"data-testid":`checkbox-${_vm.id}`,"name":`${_vm.id}-name`,"type":"checkbox","disabled":_vm.disabled},domProps:{"value":_vm.value,"checked":_vm.value},on:{"change":_vm.onChange,"input":function($event){return _vm.$emit('input', $event.target.checked)}}}),_vm._v(" "),_c('label',{staticClass:"inline-block w-full text-md font-medium select-none",class:[
            _vm.disabled
                ? 'text-gray-400 cursor-auto'
                : 'text-gray-700 cursor-pointer'
        ],attrs:{"for":_vm.id}},[_vm._v("\n        "+_vm._s(_vm.$t(_vm.labelKey))+"\n    ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./AppMenu.vue?vue&type=template&id=78baa65a&scoped=true"
import script from "./AppMenu.vue?vue&type=script&lang=ts"
export * from "./AppMenu.vue?vue&type=script&lang=ts"
import style0 from "./AppMenu.vue?vue&type=style&index=0&id=78baa65a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78baa65a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/var/www/mysite/components/Logo.vue').default})

import { Vehicle } from '@/interfaces/vehicle'
import { FilterList } from '@/interfaces/filters'
import { axiosWrapper } from '~/utilitary/storeWrapperVuex'
import { convertDate } from './charge'

const formatVehicle = (vehicle: Vehicle) => {
    const vehicleFormated = {
        immatriculation: vehicle.immatriculation,
        additional_information: vehicle.additionalInformation,
        version_id: vehicle.version?.id || null,
        rfid_passes: vehicle.passes.map((p) => p.id)
    }
    return vehicleFormated
}
const actions = {
    getVehicles(
        _: any,
        payload: { idOrga: number; idBU: number; filters: FilterList }
    ) {
        const { idOrga, idBU, filters } = payload
        const offset =
            (filters.pages.index - 1) * filters.pages.perPage || (0 as number)
        const limit = filters.pages.perPage || (20 as number)
        const keyword = filters.searchField || (null as null | string)
        const orderBy = filters.orderBy || (null as null | string)
        let params = {
            offset,
            limit,
            keyword,
            orderBy,
            has_business_pass: filters.hasBusinessPass,
            has_domestic_zone: filters.hasDomesticZone,
            min_date_created: undefined as string | undefined | null,
            max_date_created: undefined as string | undefined | null
        }

        if (filters.dateRange) {
            const { minStartDate, maxStartDate } = convertDate(
                filters.dateRange[0],
                filters.dateRange[1]
            )
            if (minStartDate) {
                params = { ...params, min_date_created: minStartDate }
            }
            if (maxStartDate) {
                params = { ...params, max_date_created: maxStartDate }
            }
        }

        return new Promise((resolve, reject) => {
            const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles`
            ;(this as any).$axios
                .$get(url, { params })
                .then((res: any) => {
                    const vehicles = (this as any).$toCamel(res)
                    resolve(vehicles)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    getVehicle(
        _: any,
        payload: { idOrga: number; idBU: number; idVehicle: number }
    ) {
        const { idOrga, idBU, idVehicle } = payload
        return new Promise((resolve, reject) => {
            const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles/${idVehicle}`
            ;(this as any).$axios
                .$get(url)
                .then((res: any) => {
                    const vehicle = (this as any).$toCamel(res)
                    resolve(vehicle)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    createVehicle(
        _: any,
        payload: { vehicle: Vehicle; idOrga: number; idBU: number }
    ) {
        const { vehicle, idOrga, idBU } = payload
        const vehicleFormated = formatVehicle(vehicle)

        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$post(
                    `/cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles`,
                    vehicleFormated
                )
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    updateVehicle(
        _: any,
        payload: { vehicle: Vehicle; idOrga: number; idBU: number }
    ) {
        const { vehicle, idOrga, idBU } = payload
        const vehicleFormated = formatVehicle(vehicle)

        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$put(
                    `/cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles/${vehicle.id}`,
                    vehicleFormated
                )
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    deleteVehicle(
        _: any,
        payload: { idVehicle: number; idOrga: number; idBU: number }
    ) {
        const { idVehicle, idOrga, idBU } = payload
        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles/${idVehicle}`
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$delete(url)
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    getBrands(_: any, payload: { searchField: string }) {
        const params = {
            keyword: payload.searchField
        }
        const url = `/cloudapi/vehicles/brands`
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$get(url, { params })
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    getModels(_: any, payload: { idBrand: number; searchField: string }) {
        const params = {
            keyword: payload.searchField
        }
        const { idBrand } = payload
        const url = `/cloudapi/vehicles/brands/${idBrand}/models`
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$get(url, { params })
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    getVersions(
        _: any,
        payload: { idBrand: number; idModel: number; searchField: string }
    ) {
        const params = {
            keyword: payload.searchField
        }
        const { idBrand, idModel } = payload
        const url = `/cloudapi/vehicles/brands/${idBrand}/models/${idModel}/versions`
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$get(url, { params })
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    import(_: any, payload: { attachment: any; idOrga: number; idBU: number }) {
        const { attachment, idOrga, idBU } = payload
        const url = `cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles/batch`
        const formData = new FormData()
        formData.append('vehicles', attachment)
        return new Promise((resolve, reject) => {
            axiosWrapper
                .bind(this)('post', url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res: { imported: number; ignored: number }) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }
}

export default {
    namespaced: true as true,
    actions
}

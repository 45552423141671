
import Vue from 'vue'
import IconPlus from '~/components/UI/icons/IconPlus.vue'
import IconPen from '~/components/UI/icons/IconPen.vue'

export default Vue.extend({
    name: 'HeaderButton',
    components: { IconPlus, IconPen },
    props: {
        url: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: 'IconPlus'
        },
        alt: {
            type: String,
            default: 'clear'
        }
    },
    methods: {
        redirect() {
            if (this.url) {
                this.$router.push(this.url)
            }
        }
    }
})

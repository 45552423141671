import { createLocalVue, mount } from '@vue/test-utils'
import * as Vuex from 'vuex'
// @ts-ignore
import AppBottomSheet from '@/components/UI/AppBottomSheet.vue'
import mobileFilterModule from '~/test/mocks/store/mobileFilter.mock'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()
localVue.use(Vuex)

const modules = {
    mobileFilter: mobileFilterModule
}
const store = new Vuex.Store({
    modules
})

describe('components/UI/AppBottomSheet.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = mount(AppBottomSheet, {
            ...basicMountWrapper,
            localVue,
            store
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const bsFilter = wrapper.find('.bs-filter')
        expect(bsFilter.exists()).toBeTruthy()
    })

    it('should resetFilters', () => {
        const spyStoreCommit = jest.spyOn(wrapper.vm.$store, 'commit')
        wrapper.vm.resetFilters()
        expect(spyStoreCommit).toHaveBeenCalledWith(
            'mobileFilter/setFilters',
            []
        )
    })
    it('should addFilter', () => {
        const spyStoreCommit = jest.spyOn(wrapper.vm.$store, 'commit')
        wrapper.vm.addFilter({ id: 1, value: 'test' })
        expect(spyStoreCommit).toHaveBeenCalledWith('mobileFilter/addFilter', {
            id: 1,
            value: 'test'
        })
    })
    it('should removeFilter', () => {
        const spyStoreCommit = jest.spyOn(wrapper.vm.$store, 'commit')
        wrapper.vm.removeFilter(1)
        expect(spyStoreCommit).toHaveBeenCalledWith(
            'mobileFilter/removeFilter',
            1
        )
    })
    it('update', () => {
        const spyStoreCommit = jest.spyOn(wrapper.vm.$store, 'commit')
        wrapper.vm.update({ id: 1, value: 'test' })
        expect(spyStoreCommit).toHaveBeenCalledWith(
            'mobileFilter/updateFilter',
            { id: 1, value: 'test' }
        )
    })

    it('reset', () => {
        const spyStoreCommit = jest.spyOn(wrapper.vm.$store, 'commit')
        wrapper.vm.reset()
        expect(spyStoreCommit).toHaveBeenCalledWith('mobileFilter/resetFilter')
        expect(spyStoreCommit).toHaveBeenCalledWith(
            'mobileFilter/setToUpdate',
            true
        )
    })

    it('close', () => {
        const { showBottomSheet } = wrapper.vm
        const spyStoreCommit = jest.spyOn(wrapper.vm.$store, 'commit')
        wrapper.vm.close()
        expect(spyStoreCommit).toHaveBeenCalledWith(
            'mobileFilter/setShowBottomSheet',
            !showBottomSheet
        )
        expect(spyStoreCommit).toHaveBeenCalledWith(
            'mobileFilter/setToUpdate',
            true
        )
    })
})

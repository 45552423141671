import { CallForFund } from '@/interfaces/callForFunds'
import { CallForFundAndExpenseReportsFilter } from '~/interfaces/filters'

const state = () => ({
    callForFunds: [],
    callForFundsCount: null as number | null
})

const getters = {
    getCallForFunds(state: any) {
        return state.callForFunds
    },
    getCallForFundsCount(state: any) {
        return state.callForFundsCount
    }
}

const mutations = {
    setCallForFunds(state: any, callForFunds: CallForFund[]) {
        state.callForFunds = callForFunds
    },
    setCallForFundsCount(state: any, count: number) {
        state.callForFundsCount = count
    }
}

const actions = {
    fetchCallForFunds(
        this: any,
        { commit }: { commit: any },
        payload: {
            idOrga: number | string | undefined
            idBU: number | string | undefined
            filters: CallForFundAndExpenseReportsFilter
        }
    ) {
        const { filters } = payload
        const { pages, minStartDate, state, keyword } = filters
        const { idOrga, idBU } = payload
        const offset = (pages.index - 1) * pages.perPage || 0
        const limit = pages.perPage || 20
        const params = {
            offset,
            limit,
            min_start_date: minStartDate || null,
            state: state || null,
            keyword
        }
        let url = `refundyapi`
        if (idOrga) {
            url += `/organizations/${idOrga}`
        }
        if (idBU) {
            url += `/business-units/${idBU}`
        }
        url += `/calls_for_funds`

        return this.$axios
            .get(url, { params })
            .then((res: any) => {
                const result = this.$toCamel(res.data)
                commit('setCallForFunds', result.items)
                const count = parseInt(res.headers['x-total-count'], 10)
                commit('setCallForFundsCount', count)
                return result
            })
            .catch((e: any) => {
                throw e
            })
    },
    generateArchive(
        this: any,
        _: any,
        payload: {
            idOrga: number | string | undefined
            idBU: number | string | undefined
            numberCallForFunds: number | string | undefined
        }
    ) {
        const { idOrga, idBU, numberCallForFunds } = payload
        return this.$axios
            .get(
                `refundyapi/organizations/${idOrga}/business-units/${idBU}/calls_for_funds/${numberCallForFunds}/request_archive`
            )
            .catch((e: any) => {
                throw e
            })
    }
}

export default {
    namespaced: true as true,
    state,
    getters,
    mutations,
    actions
}

import Vue from 'vue'
import { get } from 'lodash'
import ChargemapDialog from '@/components/UI/CMUI/ChargemapDialog.vue'
import { CssClasses, DialogType } from '@/interfaces/ui.ts'

export type DialogOptions = {
    titleTag?: string
    title?: string
    icon?: string
    textTag?: string
    text?: string
    clickToClose?: boolean
    escToClose?: boolean
    cancelButtonText?: string
    cancelButtonAriaLabel?: string
    okButtonText?: string
    okButtonAriaLabel?: string
    showCloseButton?: boolean
    disableBodyScroll?: boolean
    focusOnOpen?: boolean
    target?: string
    fixedClasses?: CssClasses
    classes?: CssClasses
    variant?: string
    inputAttributes?: { [key: string]: string }
}

export type DialogProps = {
    titleTag: string
    title?: string
    icon?: string
    textTag: string
    text?: string
    clickToClose: boolean
    escToClose: boolean
    cancelButtonText: string
    cancelButtonAriaLabel?: string
    okButtonText: string
    okButtonAriaLabel?: string
    showCloseButton: boolean
    disableBodyScroll: boolean
    focusOnOpen: boolean
    fixedClasses?: CssClasses
    classes?: CssClasses
    variant?: string
    inputAttributes?: { [key: string]: string }
    type: DialogType
}

const parseDialogOptions = (
    type: DialogType,
    titleOrDialogOptions?: DialogOptions | string,
    text?: string,
    icon?: string
) => {
    type DialogComponent = typeof ChargemapDialog & {
        options: {
            props: {
                [key in keyof DialogProps]: {
                    default: {
                        default:
                            | string
                            | boolean
                            | undefined
                            | null
                            | CssClasses
                    }
                }
            }
        }
    }

    const { props } = (ChargemapDialog as DialogComponent).options

    const propsData: Partial<DialogProps> = {
        type
    }

    let target = 'body'

    if (titleOrDialogOptions) {
        if (typeof titleOrDialogOptions === 'object') {
            Object.keys(props).forEach((propName) => {
                if (propName in titleOrDialogOptions) {
                    const defaultValue = get(props, `${propName}.default`)
                    propsData[propName as keyof DialogProps] = get(
                        titleOrDialogOptions,
                        propName,
                        defaultValue
                    )
                }
            })

            if (titleOrDialogOptions.target) {
                // eslint-disable-next-line prefer-destructuring
                target = titleOrDialogOptions.target
            }
        } else if (typeof titleOrDialogOptions === 'string') {
            propsData.title = titleOrDialogOptions
            if (typeof text !== 'undefined') {
                propsData.text = text
            }
            if (typeof icon !== 'undefined') {
                propsData.icon = icon
            }
        }
    }

    return {
        propsData,
        target
    }
}

const buildDialog = (target: string, propsData: Partial<DialogProps>) => {
    const domTarget = document.querySelector(target)

    if (!domTarget) {
        throw new Error('Target not found!')
    }

    const instance = new ChargemapDialog({
        propsData
    })

    instance.$mount()

    domTarget.appendChild(instance.$el)

    instance.updateModalVisibility(true)

    return new Promise((resolve, reject) => {
        instance.resolve = resolve
        instance.reject = reject
    })
}

if (Vue.prototype && !Vue.prototype?.$dialog) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$dialog = new Vue({
        methods: {
            confirm(
                titleOrDialogOptions?: DialogOptions | string,
                text?: string | undefined,
                icon?: string | undefined
            ) {
                const { propsData, target } = parseDialogOptions(
                    DialogType.Confirm,
                    titleOrDialogOptions,
                    text,
                    icon
                )

                return buildDialog(target, propsData)
            }
        }
    })
}

import { CommandType, SpecificPayload } from '~/interfaces/chargebox'

const state = () => ({
    isReloadingList: [] as Array<string>
})

const getters = {
    getIsReloadingList(state: any) {
        return state.isReloadingList
    }
}

const mutations = {
    addIsReloadingList(state: any, idCharge: string) {
        state.isReloadingList.push(idCharge)
    },
    removeIsReloadingList(state: any, idCharge: string) {
        state.isReloadingList = state.isReloadingList.filter(
            (id: string) => id !== idCharge
        )
    }
}

const actions = {
    downloadFile(
        this: any,
        _: any,
        payload: {
            idBU: number
            idChargeBoxes: string
        }
    ) {
        const { idBU, idChargeBoxes } = payload
        let url = `/supervisionapi`
        url += `/business-units/${idBU}`
        url += `/chargeboxes/${idChargeBoxes}/configuration`
        return this.$axios
            .get(url)
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    },
    resetConfig(
        this: any,
        _: any,
        payload: {
            idBU: number
            idChargeBoxes: string
        }
    ) {
        const { idBU, idChargeBoxes } = payload
        let url = `/supervisionapi`
        url += `/business-units/${idBU}`
        url += `/chargeboxes/${idChargeBoxes}/configuration/reset`
        return this.$axios
            .post(url)
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    },
    resetHard(
        context: any,
        payload: {
            idBU: number
            idChargeBoxes: string
        }
    ) {
        const params = {
            ...payload,
            action: CommandType.RESET,
            specificPayload: {
                type: 'HARD'
            }
        }
        const { idChargeBoxes } = payload
        return context
            .dispatch('command', params)
            .then((response: { status: 'FAILED' | 'SUCCESS' }) => {
                if (response?.status === 'SUCCESS') {
                    context.commit('addIsReloadingList', idChargeBoxes)
                    const remove = () =>
                        context.commit('removeIsReloadingList', idChargeBoxes)
                    setTimeout(remove, 1_000 * 60 * 3)
                }
                return response
            })
    },
    changeConfiguration(
        context: any,
        payload: {
            idBU: number
            idChargeBoxes: string
            key: string
            value: string
        }
    ) {
        const { idBU, idChargeBoxes, key, value } = payload
        const params = {
            idBU,
            idChargeBoxes,
            action: CommandType.CHANGE_CONFIGURATION,
            specificPayload: {
                key,
                value
            }
        }
        return context.dispatch('command', params)
    },
    unlock(
        context: any,
        payload: {
            idBU: number
            idChargeBoxes: string
            idConnector: number
        }
    ) {
        const { idBU, idChargeBoxes, idConnector } = payload
        const params = {
            idBU,
            idChargeBoxes,
            action: CommandType.UNLOCK,
            specificPayload: {
                connector_id: idConnector
            }
        }
        return context.dispatch('command', params)
    },
    refreshStatus(
        context: any,
        payload: {
            idBU: number
            idChargeBoxes: string
        }
    ) {
        const { idBU, idChargeBoxes } = payload
        const params = {
            idBU,
            idChargeBoxes,
            action: CommandType.TRIGGER,
            specificPayload: {
                requested_message: 'STATUS_NOTIFICATION'
            }
        }
        return context.dispatch('command', params)
    },
    play(
        context: any,
        payload: {
            idBU: number
            idChargeBoxes: string
            idPass: number
            idConnector: number
        }
    ) {
        const { idBU, idChargeBoxes, idPass, idConnector } = payload
        const params = {
            idBU,
            idChargeBoxes,
            action: CommandType.REMOTE_START_TRANSACTION,
            specificPayload: {
                pass_id: idPass,
                connector_id: idConnector
            }
        }
        return context.dispatch('command', params)
    },
    stop(
        context: any,
        payload: {
            idBU: number
            idChargeBoxes: string
            idConnector: number
        }
    ) {
        const { idBU, idChargeBoxes, idConnector } = payload
        const params = {
            idBU,
            idChargeBoxes,
            action: CommandType.REMOTE_STOP_TRANSACTION,
            specificPayload: {
                connector_id: idConnector
            }
        }
        return context.dispatch('command', params)
    },
    updateFirmware(
        context: any,
        payload: {
            idBU: number
            idChargeBoxes: string
            firmwareUri: string
        }
    ) {
        const { idBU, idChargeBoxes, firmwareUri } = payload
        const params = {
            idBU,
            idChargeBoxes,
            action: CommandType.UPDATE_FIRMWARE,
            specificPayload: {
                firmware_uri: firmwareUri
            }
        }
        return context.dispatch('command', params)
    },
    command(
        this: any,
        _: any,
        payload: {
            idBU: number
            idChargeBoxes: string
            action: CommandType
            specificPayload: SpecificPayload
        }
    ) {
        const { idBU, idChargeBoxes, action, specificPayload } = payload
        return this.$axios
            .$post(
                `/supervisionapi/business-units/${idBU}/chargeboxes/${idChargeBoxes}/actions`,
                {
                    action,
                    specific_payload: specificPayload
                }
            )
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    }
}

export default {
    namespaced: true as true,
    state,
    getters,
    mutations,
    actions
}

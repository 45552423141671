import { DeliveryPoint } from '~/interfaces/deliveryPoint'
import { FilterList } from '~/interfaces/filters'

const actions = {
    fetchDeliveryPoints(
        this: any,
        _: any,
        payload: {
            idBU: number
            hasZones: boolean
            hasOwner: boolean
            filters: FilterList
        }
    ): Promise<{ count: number; deliveryPoints: DeliveryPoint[] }> {
        const { idBU, hasZones, hasOwner, filters } = payload
        const offset =
            (filters.pages.index - 1) * filters.pages.perPage || (0 as number)
        const limit = filters.pages.perPage || (20 as number)
        const keyword = filters.searchField || (null as null | string)
        const params = {
            has_zones: hasZones,
            has_owner: hasOwner,
            offset,
            limit,
            keyword
        }
        const url = `/supervisionapi/business-units/${idBU}/delivery-points`
        return this.$axios
            .get(url, { params })
            .then((response: any) => {
                const resDTO = response.data.items
                const count = parseInt(response.headers['x-total-count'], 10)
                const deliveryPoints = (this as any).$toCamel(
                    resDTO
                ) as DeliveryPoint[]
                const convertRate = (
                    deliveryPoint: DeliveryPoint
                ): DeliveryPoint => {
                    const TO_EUROS = 100_000
                    const TO_KW = 1_000
                    const {
                        reducedPeriod: reducedPeriodRaw,
                        price,
                        billEnergy,
                        billedEnergyMargin
                    } = deliveryPoint.tariff
                    let reducedPeriod = null
                    if (reducedPeriodRaw) {
                        reducedPeriod = {
                            ...reducedPeriodRaw,
                            price: reducedPeriodRaw.price / TO_EUROS
                        }
                    }
                    return {
                        ...deliveryPoint,
                        tariff: {
                            reducedPeriod,
                            price: price / TO_EUROS,
                            billEnergy,
                            billedEnergyMargin
                        },
                        maxPower: deliveryPoint.maxPower
                            ? deliveryPoint.maxPower / TO_KW
                            : null
                    }
                }
                return {
                    count,
                    deliveryPoints: deliveryPoints.map(convertRate)
                }
            })
            .catch((e: any) => {
                throw e
            })
    },
    fetchDeliveryPoint(
        this: any,
        _: any,
        payload: { idBU: number; idDeliveryPoint: number }
    ) {
        const { idBU, idDeliveryPoint } = payload
        const url = `/supervisionapi/business-units/${idBU}/delivery-points/${idDeliveryPoint}`
        return this.$axios
            .$get(url)
            .then((res: any) => {
                const deliveryPoint = (this as any).$toCamel(res)
                const convertRate = (
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    deliveryPoint: DeliveryPoint
                ): DeliveryPoint => {
                    const TO_EUROS = 100_000
                    const TO_KW = 1_000
                    const {
                        reducedPeriod: reducedPeriodRaw,
                        price,
                        billEnergy,
                        billedEnergyMargin
                    } = deliveryPoint.tariff
                    let reducedPeriod = null
                    if (reducedPeriodRaw) {
                        reducedPeriod = {
                            ...reducedPeriodRaw,
                            price: reducedPeriodRaw.price / TO_EUROS
                        }
                    }
                    return {
                        ...deliveryPoint,
                        tariff: {
                            reducedPeriod,
                            price: price / TO_EUROS,
                            billEnergy,
                            billedEnergyMargin
                        },
                        maxPower: deliveryPoint.maxPower
                            ? deliveryPoint.maxPower / TO_KW
                            : null
                    }
                }
                return convertRate(deliveryPoint)
            })
            .catch((e: any) => {
                throw e
            })
    },
    saveDeliveryPoint(
        this: any,
        _: any,
        payload: { deliveryPoint: DeliveryPoint; idBU: number }
    ) {
        const { deliveryPoint, idBU } = payload
        let url = `/supervisionapi/business-units/${idBU}/delivery-points`
        let method = '$post'
        if (deliveryPoint.id) {
            url += `/${deliveryPoint.id}`
            method = '$put'
        }
        const body = {
            ...deliveryPoint,
            id: undefined
        }

        return this.$axios[method](url, body)
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    },
    deleteDeliveryPoint(
        this: any,
        _: any,
        payload: { idDeliveryPoint: number; idBU: number }
    ) {
        const { idDeliveryPoint, idBU } = payload
        const url = `/supervisionapi/business-units/${idBU}/delivery-points/${idDeliveryPoint}`
        return this.$axios
            .$delete(url)
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    }
}

export default {
    namespaced: true as true,
    actions
}

import { Filters } from '~/interfaces/filters'

const state = () => ({
    filters: [] as Filters[],
    showBottomSheet: false as boolean,
    showSearchBar: false as boolean,
    hasSearch: false as boolean,
    toUpdate: false as boolean
})

const getters = {
    getFilters(state: any) {
        return state.filters
    },
    getFilter(state: any, filter: any) {
        return state.filters.find((f: any) => f.id === filter.id)
    },
    getShowBottomSheet(state: any) {
        return state.showBottomSheet
    },
    getShowSearchBar(state: any) {
        return state.showSearchBar
    },
    getHasSearch(state: any) {
        return state.hasSearch
    },
    getToUpdate(state: any) {
        return state.toUpdate
    }
}

const mutations = {
    setFilters(state: any, filters: any) {
        state.filters = filters
    },
    setShowBottomSheet(state: any, showBottomSheet: boolean) {
        state.showBottomSheet = showBottomSheet
    },
    setShowSearchBar(state: any, showSearchBar: boolean) {
        state.showSearchBar = showSearchBar
    },
    setToUpdate(state: any, toUpdate: boolean) {
        state.toUpdate = toUpdate
    },
    addFilter(state: any, filter: any) {
        state.filters.push(filter)
    },
    removeFilter(state: any, idFilter: number) {
        state.filters = state.filters.filter((f: any) => f.id !== idFilter)
    },
    resetFilter(state: any) {
        state.filters.forEach((filter: any) => {
            filter.value = null
        })
    },
    updateFilter(state: any, filter: any) {
        const filterMatch = getters.getFilter(state, filter)
        if (filterMatch) {
            filterMatch.value = filter.value
        }
    },
    setHasSearch(state: any, hasSearch: boolean) {
        state.hasSearch = hasSearch
    }
}

export default {
    namespaced: true as true,
    state,
    getters,
    mutations
}

import Vue from 'vue'

const eventMenu = {}

// eslint-disable-next-line @typescript-eslint/no-shadow
eventMenu.install = function (Vue) {
    Vue.prototype.$menu = new Vue()
}

Vue.use(eventMenu)

import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'

// @ts-ignore
import AppTitle from '@/components/UI/AppTitle.vue'
import router from '~/test/mocks/router.mock'

// Mocks
import mobileFilterModule from '~/test/mocks/store/mobileFilter.mock'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()
localVue.use(Vuex)

const menu = {
    $emit: jest.fn()
}
const modules = {
    mobileFilter: mobileFilterModule
}
const store = new Vuex.Store({
    modules
})

describe('components/UI/AppTitle.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppTitle, {
            ...basicMountWrapper,
            localVue,
            store,
            mocks: {
                ...basicMountWrapper.mocks,
                $menu: menu
            }
        })
    })

    afterEach(() => {
        jest.clearAllMocks()
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
    })

    it('emit toggle menu', async () => {
        expect(menu.$emit).not.toHaveBeenCalled()
        await wrapper.vm.toggleMobileMenu()
        expect(menu.$emit).toHaveBeenCalledWith('toggle')
    })

    it('emit toggle bottom sheet', async () => {
        const { showBottomSheet } = wrapper.vm
        const spyStoreCommit = jest.spyOn(wrapper.vm.$store, 'commit')
        await wrapper.vm.toggleBottomSheet()
        expect(spyStoreCommit).toHaveBeenCalledWith(
            'mobileFilter/setShowBottomSheet',
            !showBottomSheet
        )
    })

    it('emit toggle search', async () => {
        const { showSearchBar } = wrapper.vm
        const spyStoreCommit = jest.spyOn(wrapper.vm.$store, 'commit')
        await wrapper.vm.toggleSearch()
        expect(spyStoreCommit).toHaveBeenCalledWith(
            'mobileFilter/setShowSearchBar',
            !showSearchBar
        )
    })

    describe('goBack', () => {
        it('use browser back', () => {
            wrapper.vm.goBack()
            expect(router.go).toHaveBeenCalledWith(-1)
        })
    })
})

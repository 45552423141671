import useChargeStore from '~/store/charge'
import {
    BillingRecipient,
    BusinessUnit,
    BusinessUnitJSON,
    BillingAccount,
    BillingAccountJSON,
    PaymentMean,
    PaymentMeanJSON,
    OnboardingState,
    OnboardingStateJSON
} from '@/interfaces/businessunit'
import { BusinessFilter } from '@/interfaces/filters'
import { axiosWrapper } from '~/utilitary/storeWrapperVuex'
import { UserOrganization } from '~/interfaces/organization'

const state = () => ({
    businessunit: null,
    keyword: '' as string,
    onboardingState: null as OnboardingState | null
})

const getters = {
    getBusinessUnit(state: any) {
        return state.businessunit
    },
    getOnboardingState(state: any) {
        return state.onboardingState
    },
    getRoles(state: any) {
        return state.businessunit?.roles
    },
    hasOnboardingState(state: any) {
        return state.onboardingState !== null
    },
    hasFilled3OnboardingState(state: any) {
        return (
            state.onboardingState?.hasBillingAddress &&
            state.onboardingState?.hasShippingAddress &&
            state.onboardingState?.hasPaymentMean
        )
    },
    hasBillingAddress(state: any) {
        return state.onboardingState?.hasBillingAddress
    },
    getRolesFromOrganization(
        state: any,
        organization: UserOrganization,
        index: number | null = null
    ) {
        const roles = []

        if (organization) {
            if (index === 0 && organization?.businessUnits)
                return organization?.businessUnits[index]?.roles

            const businessUnit = organization?.businessUnits?.find(
                (bu: BusinessUnit) => bu.id === state.businessunit?.id
            )

            if (businessUnit && businessUnit.roles) {
                roles.push(...businessUnit.roles)
            }
        }
        return roles
    },
    getKeyword(state: any) {
        return state.keyword
    }
}

const mutations = {
    setBusinessUnit(state: any, bu: {}) {
        state.businessunit = bu
    },
    setKeyword(state: any, keyword: string) {
        state.keyword = keyword
    },
    setOnboardingState(state: any, onboardingState: OnboardingState) {
        state.onboardingState = onboardingState
    },
    setRoles(state: any, newRoles: string[]) {
        if (state.businessunit) {
            state.businessunit.roles = newRoles
        }
    }
}

const actions = {
    setKeyword(vuexContext: any, keyword: string) {
        vuexContext.commit('setKeyword', keyword)
        return Promise.resolve()
    },
    async initBusinessUnit(vuexContext: any, idBU: string | null = null) {
        const organization =
            vuexContext.rootGetters['organization/getOrganization']

        if (organization) {
            return vuexContext
                .dispatch('getBusinessUnit', {
                    idOrga: organization.id,
                    idBU: idBU || organization?.businessUnits[0].id
                })
                .then((res: any) => {
                    if (!res) {
                        throw new Error('Business unit not found')
                    }
                    res.roles = getters.getRolesFromOrganization(
                        vuexContext.state,
                        organization,
                        0
                    )
                    return vuexContext.dispatch('setBusinessUnit', res)
                })
        }

        return vuexContext.dispatch(
            'setBusinessUnit',
            !vuexContext.rootGetters['session/isAdmin']
                ? organization?.businessUnits[0]
                : null
        )
    },
    switchBusinessUnit(
        vuexContext: any,
        params: { idBU: number; idOrga: number }
    ) {
        return vuexContext
            .dispatch('getBusinessUnit', {
                idOrga: params.idOrga,
                idBU: params.idBU
            })
            .then((bu: BusinessUnit) => {
                vuexContext.dispatch('setBusinessUnit', bu)
            })
    },
    async setBusinessUnit(vuexContext: any, bu?: BusinessUnit | null) {
        const organization =
            vuexContext.rootGetters['organization/getOrganization']
        if (bu && !bu.roles)
            bu.roles = getters.getRolesFromOrganization(
                vuexContext.state,
                organization
            )
        vuexContext.commit('setBusinessUnit', bu)
        useChargeStore().resetCharge()
        await vuexContext.dispatch('session/updateBusinessUnitRoles', null, {
            root: true
        })
        return Promise.resolve()
    },
    async createBusinessUnit(
        this: any,
        vuexContext: any,
        payload: { idOrga: number; bu: BusinessUnit }
    ) {
        const { idOrga } = payload
        const bu: BusinessUnitJSON = (this as any).$toSnake(payload.bu)
        delete bu.id
        if (bu.administrator.id === 0) {
            delete bu.administrator.id
        }
        const url = `/cloudapi/organizations/${idOrga}/business-units`
        const params = bu
        const res = await axiosWrapper.bind(this)('post', url, params)
        payload.bu.id = res.id
        if (res.administrator?.id) {
            payload.bu.administrator.id = res.administrator.id
        }
        vuexContext.commit(
            'session/pushBusinessUnit',
            { idOrga, newBusinessUnit: payload.bu },
            { root: true }
        )
        const user = vuexContext.rootGetters['session/getUser']
        this.$auth.setUser(user)
        this.$auth.$storage.setUniversal('user', user)
        await this.$auth.refreshTokens()
        return res
    },
    updateBusinessUnit(
        this: any,
        vuexContext: any,
        params: { idOrga: number; bu: BusinessUnit }
    ) {
        return new Promise((resolve, reject) => {
            const payload: BusinessUnitJSON = (this as any).$toSnake({
                name: params.bu.name,
                notifyMainContact: params.bu.notifyMainContact
            })

            ;(this as any).$axios
                .$patch(
                    `/cloudapi/organizations/${params.idOrga}/business-units/${params.bu.id}`,
                    payload
                )
                .then((bu: BusinessUnit) => {
                    vuexContext.dispatch('setBusinessUnit', this.$toCamel(bu))
                    vuexContext.commit(
                        'session/updateBusinessUnit',
                        { idOrga: params.idOrga, businessUnit: params.bu },
                        { root: true }
                    )
                    const user = vuexContext.rootGetters['session/getUser']
                    this.$auth.setUser(user)
                    this.$auth.$storage.setUniversal('user', user)
                    return this.$auth
                        .refreshTokens()
                        .then(() => resolve(true))
                        .catch((e: any) => {
                            reject(e)
                        })
                })
                .catch((e: any) => {
                    reject(e.response)
                })
        })
    },
    setBusinessUnitState(
        _vuexContext: any,
        payload: { idOrga: number; bu: BusinessUnit }
    ) {
        return new Promise((resolve, reject) => {
            const { idOrga } = payload
            const bu = {
                activated: payload.bu.activated
            }
            ;(this as any).$axios
                .$patch(
                    `/cloudapi/organizations/${idOrga}/business-units/${payload.bu.id}`,
                    bu
                )
                .then((res: any) => {
                    try {
                        ;(this as any).$auth.refreshTokens()
                    } catch (error) {
                        reject(error)
                    }
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e.response)
                })
        })
    },
    async getBusinessUnit(
        vuexContext: any,
        payload: { idOrga: number; idBU: number }
    ) {
        let error: any
        let businessUnit = null as BusinessUnit | null
        const { idOrga, idBU } = payload
        await vuexContext
            .dispatch('fetchBusinessUnit', { idOrga, idBU })
            .then((bu: BusinessUnit) => {
                if (bu === null) {
                    error = new Error('Business Unit not found')
                }
                businessUnit = { ...bu }
            })
            .catch((e: any) => e)
        await Promise.all([
            vuexContext
                .dispatch('getBillingAccount', payload)
                .then((billingAccount: BillingAccount) => {
                    if (businessUnit && billingAccount) {
                        businessUnit.billingAccount = billingAccount
                    }
                })
                .catch((e: any) => e),
            vuexContext
                .dispatch('getPaymentMean', payload)
                .then((paymentMean: PaymentMean) => {
                    if (businessUnit && paymentMean) {
                        businessUnit.paymentMean = paymentMean
                    }
                })
                .catch((e: any) => e),
            vuexContext
                .dispatch('fetchBillingRecipients', payload)
                .then((billingRecipients: BillingRecipient[]) => {
                    if (businessUnit && billingRecipients) {
                        businessUnit.billingRecipients = billingRecipients
                    }
                })
                .catch((e: any) => e)
        ])

        return new Promise((resolve, reject) => {
            if (error) reject(error)
            resolve(businessUnit)
        })
    },
    async fetchBusinessUnit(
        this: any,
        _: any,
        payload: { idOrga: number; idBU: number }
    ) {
        const { idOrga, idBU } = payload
        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}`
        const businessUnit = await this.$axios.$get(url)
        return this.$toCamel(businessUnit)
    },
    fetchBusinessUnits(
        _: any,
        payload: { idOrga: number; filters: BusinessFilter }
    ) {
        const offset =
            (payload.filters.pages.index - 1) * payload.filters.pages.perPage ||
            (0 as number)
        const limit = payload.filters.pages.perPage || (20 as number)
        const isActivated = !payload.filters.hasAllBusinessUnit || null
        const keyword = payload.filters.searchField || (null as null | string)
        const params = { offset, limit, activated: isActivated, keyword }
        return new Promise((resolve, reject) => {
            const url = `/cloudapi/organizations/${payload.idOrga}/business-units`
            ;(this as any).$axios
                .$get(url, { params })
                .then((res: any) => {
                    const businessUnits = (this as any).$toCamel(res)
                    resolve(businessUnits)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    getBillingAccount(context: any, payload: { idOrga: number; idBU: number }) {
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$get(
                    `/cloudapi/organizations/${payload.idOrga}/business-units/${payload.idBU}/billing-account`
                )
                .then((res: BillingAccountJSON) => {
                    const billingAccount: BillingAccount = (
                        this as any
                    ).$toCamel(res)
                    resolve(billingAccount)
                })
                .catch((e: any) => {
                    if (e?.response?.status === 404) {
                        const billingAccount: BillingAccount = {
                            email: '',
                            lang: 'FR',
                            type: 'COMPANY',
                            paymentTerm: 0,
                            billingAddress: {
                                firstName: '',
                                name: '',
                                company: '',
                                addressLine1: '',
                                addressLine2: '',
                                postalCode: '',
                                city: '',
                                countryCode: '',
                                countryName: '',
                                phone: '',
                                vatNumber: ''
                            },
                            shippingSameAsBilling: true,
                            shippingAddress: {
                                firstName: '',
                                name: '',
                                company: '',
                                addressLine1: '',
                                addressLine2: '',
                                postalCode: '',
                                city: '',
                                countryCode: '',
                                countryName: '',
                                phone: '',
                                vatNumber: ''
                            },
                            additionalInformation: '',
                            additionalInformationValidity: undefined
                        }

                        context.commit(
                            'payment/setBillingAccount',
                            billingAccount,
                            {
                                root: true
                            }
                        )
                    }

                    reject(e)
                })
        })
    },
    updateBillingAccount(
        _: any,
        payload: {
            idOrga: number
            idBU: number
            billingAccount: BillingAccount
        }
    ) {
        return new Promise((resolve, reject) => {
            if (!payload.billingAccount.additionalInformationValidity) {
                payload.billingAccount.additionalInformationValidity = undefined
            }
            const billingAccountJSON: BillingAccountJSON = (
                this as any
            ).$toSnake(payload.billingAccount)
            ;(this as any).$axios
                .$put(
                    `/cloudapi/organizations/${payload.idOrga}/business-units/${payload.idBU}/billing-account`,
                    billingAccountJSON
                )
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    getPaymentMean(_: any, payload: { idOrga: number; idBU: number }) {
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$get(
                    `/cloudapi/organizations/${payload.idOrga}/business-units/${payload.idBU}/payment-mean`
                )
                .then((res: PaymentMeanJSON) => {
                    let payment: PaymentMean = (this as any).$toCamel(res)
                    if (!payment) {
                        payment = {
                            id: null,
                            isDefault: false,
                            type: null
                        }
                    }
                    resolve(payment)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    fetchOnboardingState(
        this: any,
        context: any,
        route: { idOrga: number; idBU: number }
    ) {
        const { idOrga, idBU } = route
        return this.$axios
            .get(
                `/cloudapi/organizations/${idOrga}/business-units/${idBU}/onboarding-state`
            )
            .then((res: { data: OnboardingStateJSON }) => {
                const onboardingState: OnboardingState = (this as any).$toCamel(
                    res.data
                )
                context.commit('setOnboardingState', onboardingState)
                return onboardingState
            })
            .catch((e: any) => {
                throw e
            })
    },
    patchOnboardingState(
        this: any,
        _: any,
        payload: {
            idOrga: number
            idBU: number
            onBoardingState: {
                has_pass_orders?: boolean
                has_collaborators_or_vehicles?: boolean
                has_attached_passes?: boolean
            }
        }
    ) {
        const { idOrga, idBU, onBoardingState } = payload

        return this.$axios
            .$patch(
                `/cloudapi/organizations/${idOrga}/business-units/${idBU}/onboarding-state`,
                onBoardingState
            )
            .catch((e: any) => {
                throw e
            })
    },
    fetchBillingRecipients(_: any, payload: { idOrga: number; idBU: number }) {
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$get(
                    `/cloudapi/organizations/${payload.idOrga}/business-units/${payload.idBU}/billing-copies`
                )
                .then((res: { id: number; email: string }[]) => {
                    let billingRecipients: { id: number; email: string }[] = []

                    const billingRecipientResults: {
                        billingCopies: { id: number; email: string }[]
                    } = (this as any).$toCamel(res)

                    if (billingRecipientResults.billingCopies) {
                        billingRecipients =
                            billingRecipientResults.billingCopies
                    }
                    resolve(billingRecipients)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    postBillingRecipient(
        _: any,
        payload: { idOrga: number; idBU: number; email: string }
    ) {
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$post(
                    `/cloudapi/organizations/${payload.idOrga}/business-units/${payload.idBU}/billing-copies`,
                    { email: payload.email }
                )
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    updateBillingRecipient(
        _: any,
        payload: {
            idOrga: number
            idBU: number
            billingRecipient: { id: number; email: string }
        }
    ) {
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$put(
                    `/cloudapi/organizations/${payload.idOrga}/business-units/${payload.idBU}/billing-copies/${payload.billingRecipient.id}`,
                    { email: payload.billingRecipient.email }
                )
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    deleteBillingRecipient(
        _: any,
        payload: {
            idOrga: number
            idBU: number
            billingRecipient: { id: number; email: string }
        }
    ) {
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$delete(
                    `/cloudapi/organizations/${payload.idOrga}/business-units/${payload.idBU}/billing-copies/${payload.billingRecipient.id}`
                )
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }
}

export default {
    namespaced: true as true,
    state,
    getters,
    mutations,
    actions
}

import { axiosWrapper } from '~/utilitary/storeWrapperVuex'

const actions = {
    logUser(this: any, vuexContext: any, params: any): Promise<void> {
        const payload = {
            data: {
                login: params.login,
                password: params.password,
                google_recaptcha_token: params.tokenCaptcha
            }
        }
        if (!this.$config.CAPTCHA_ENABLED)
            delete payload.data.google_recaptcha_token

        return this.$auth
            .loginWith('refresh', payload)
            .then((res: any) =>
                vuexContext.dispatch('session/setUpUser', res.data, {
                    root: true
                })
            )
            .catch((e: any) => {
                if (e.response && e.response.status === 401) {
                    throw new Error('errors.invalid_password')
                } else {
                    throw new Error('errors.oops')
                }
            })
    },
    logOutUser(this: any, vuexContext: any) {
        return this.$auth.strategy
            .logout({
                url: `/cloudapi/tokens/${this.$auth.strategy.refreshToken.get()}`,
                method: 'delete'
            })
            .catch((e: any) => {
                this.$sentry.captureException(e)
            })
            .finally(() => {
                vuexContext.dispatch('session/logout', null, {
                    root: true
                })
            })
    },
    sendPasswordReset(this: any, _: any, email: string): Promise<void> {
        const params = { user_email: email }
        const url = 'cloudapi/password/reset'
        return axiosWrapper.bind(this)('post', url, params)
    },
    updatePasswordReset(
        this: any,
        _: any,
        paramsPasswordReset: any
    ): Promise<void> {
        const params = {
            key: paramsPasswordReset.key,
            new_password: paramsPasswordReset.password
        }
        const url = `/cloudapi/users/${paramsPasswordReset.userId}/password`
        return axiosWrapper.bind(this)('patch', url, params)
    }
}

export default {
    namespaced: true as true,
    actions
}

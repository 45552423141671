import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'
// @ts-ignore
import AppBasicFilter from '@/components/UI/AppBasicFilter.vue'
import mobileFilterModule from '~/test/mocks/store/mobileFilter.mock'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()
localVue.use(Vuex)
const modules = {
    mobileFilter: mobileFilterModule
}
const store = new Vuex.Store({
    modules
})
const $emit = jest.fn()
const mockSlotProps = {
    toggleShowList: jest.fn()
}

describe('UI/AppBasicFilter.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppBasicFilter, {
            ...basicMountWrapper,
            localVue,
            propsData: {
                value: null,
                prefixLoco: 'prefix.'
            },
            store,
            mocks: {
                ...basicMountWrapper.mocks,
                $emit,
                slotProps: {
                    toggleShowList: jest.fn()
                }
            },
            stubs: {
                ChargemapDropdown: {
                    template: '<div class="FooComponent"></div>',
                    slotProps: {
                        toggleShowList: jest.fn()
                    }
                }
            }
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="app-basic-filter"]')
        expect(component.exists()).toBeTruthy()
    })

    describe('methods', () => {
        it('can call update', () => {
            const spyStoreCommit = jest.spyOn(wrapper.vm.$store, 'commit')
            const uid = wrapper.vm._uid
            expect(wrapper.vm.value).toEqual(null)
            wrapper.vm.update(false, mockSlotProps)
            expect($emit).toHaveBeenCalledWith('input', false)
            expect(spyStoreCommit).toHaveBeenCalledWith(
                'mobileFilter/updateFilter',
                { id: uid, value: false }
            )
        })

        it('can call updateFilter', () => {
            const uid = wrapper.vm._uid
            const badFilter = { id: 999, value: false }
            const filterValue = { id: uid, value: true }
            const spyUpdate = jest.spyOn(wrapper.vm, 'update')
            wrapper.vm.updateFilter(badFilter)
            expect(spyUpdate).not.toHaveBeenCalled()
            wrapper.vm.updateFilter(filterValue)
            expect(spyUpdate).toHaveBeenCalledWith(true)
        })

        describe('can call getLabel', () => {
            const prefix = 'prefix.'

            afterEach(() => {
                jest.clearAllMocks()
            })

            it('can return all if value = undefined', () => {
                expect(wrapper.vm.label).toEqual(
                    wrapper.vm.$tc(`${prefix}title`)
                )
            })

            it('can return all if value = false', async () => {
                await wrapper.setProps({
                    value: false
                })
                await wrapper.vm.$nextTick()
                expect(wrapper.vm.label).toEqual(wrapper.vm.$tc(`${prefix}no`))
            })

            it('can return all if value = true', async () => {
                await wrapper.setProps({
                    value: true
                })
                expect(wrapper.vm.label).toEqual(wrapper.vm.$tc(`${prefix}yes`))
            })
        })
    })
})

import {
    Organization,
    UserOrganization,
    OrganizationJSON,
    Functionality,
    Pipeline
} from '~/interfaces/organization'
import { BusinessUnitDTO } from '~/interfaces/businessunit'

import { organizationRoles } from '~/interfaces/roles'
import { OrganizationFilter } from '~/interfaces/filters'

const state = () => ({
    organization: null as Organization | null,
    keyword: '' as string
})

const getters = {
    getOrganization(state: any) {
        return state.organization
    },
    getRole(state: any) {
        return state.organization?.role
    },
    hasBlockingPass(state: any) {
        return !!state.organization?.access?.includes(
            Functionality.BLOCKING_PASS
        )
    },
    hasBetaOrderFlowAccess(state: any) {
        return state.organization?.access?.includes(Functionality.BETA) || false
    },
    getKeyword(state: any) {
        return state.keyword
    }
}

const mutations = {
    setOrganization(state: any, organization: {}) {
        state.organization = organization
    },
    setKeyword(state: any, keyword: string) {
        state.keyword = keyword
    },
    setRole(state: any, newRole: string) {
        state.organization.role = newRole
    },
    setAccess(state: any, access: string[]) {
        state.organization.access = access
    }
}

const actions = {
    setKeyword(vuexContext: any, keyword: string) {
        vuexContext.commit('setKeyword', keyword)
        return Promise.resolve()
    },
    constructOrganization(
        vuexContext: any,
        payload: { organizationId: number; idBU: number | null }
    ) {
        const lastOrga = vuexContext.rootGetters['organization/getOrganization']
        const idOrga = payload.organizationId || lastOrga?.id
        return new Promise((resolve, reject) => {
            if (!idOrga) {
                resolve('No organization set')
            }
            let organization: UserOrganization
            vuexContext
                .dispatch('getOrganization', idOrga)
                .then((res: any) => {
                    if (!res) {
                        throw new Error('Organization not found')
                    }
                    organization = res
                    const filters = {
                        searchField: '',
                        hasAllBusinessUnit: false,
                        pages: {
                            index: 1,
                            perPage: 100,
                            totalItems: 0,
                            disabled: false
                        }
                    }
                    return vuexContext.dispatch(
                        'businessunit/fetchBusinessUnits',
                        { idOrga, filters },
                        { root: true }
                    )
                })
                .then((res: BusinessUnitDTO) => {
                    organization.businessUnits = res.businessUnits
                    organization.role = organizationRoles.ADMIN
                    vuexContext.commit(
                        'session/setOrganizations',
                        [organization],
                        {
                            root: true
                        }
                    )
                    vuexContext.dispatch('setOrganization', organization)
                    if (payload.idBU) {
                        const { idBU } = payload
                        const businessUnit = organization.businessUnits.find(
                            (bu: any) => +bu.id === +idBU
                        )
                        return vuexContext
                            .dispatch(
                                'businessunit/setBusinessUnit',
                                businessUnit,
                                {
                                    root: true
                                }
                            )
                            .then(() => {
                                resolve(res)
                            })
                    }
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    async switchOrganization(
        vuexContext: any,
        payload: { organizationId: string; idBU: string | null }
    ) {
        if (vuexContext.rootGetters['session/isSuperAdmin']) {
            return vuexContext.dispatch('constructOrganization', payload)
        }

        let organization: UserOrganization | null = null

        const listOrgaFromUserObject =
            vuexContext.rootGetters['session/getUser']?.rights?.organizations
        const organizationFromUserObject = listOrgaFromUserObject?.find(
            (orga: any) =>
                parseInt(orga.id, 10) === parseInt(payload.organizationId, 10)
        )

        const role =
            organizationFromUserObject?.role || listOrgaFromUserObject[0]?.role

        if (role === 'ADMIN') {
            await vuexContext
                .dispatch(
                    'getOrganization',
                    organizationFromUserObject?.id ||
                        listOrgaFromUserObject[0]?.id
                )
                .then((res: any) => {
                    if (!res) {
                        throw new Error('Organization not found')
                    }
                    organization = res
                })
        }

        if (organization) {
            ;(organization as UserOrganization).businessUnits =
                organizationFromUserObject?.businessUnits ||
                listOrgaFromUserObject[0]?.businessUnits
            ;(organization as UserOrganization).role =
                organizationFromUserObject?.role ||
                listOrgaFromUserObject[0]?.role
            vuexContext.dispatch('setOrganization', organization)
        } else {
            vuexContext.dispatch(
                'setOrganization',
                listOrgaFromUserObject
                    ? organizationFromUserObject || listOrgaFromUserObject[0]
                    : null
            )
        }

        if (payload.idBU || role !== 'ADMIN')
            return vuexContext.dispatch(
                'businessunit/initBusinessUnit',
                payload.idBU || null,
                {
                    root: true
                }
            )
    },
    setOrganization(vuexContext: any, organization: Organization) {
        vuexContext.commit('setOrganization', organization)
        return vuexContext.dispatch('session/updateOrganizationRole', null, {
            root: true
        })
    },
    fetchOrganizations(_: any, filters: OrganizationFilter) {
        const offset =
            (filters.pages.index - 1) * filters.pages.perPage || (0 as number)
        const limit = filters.pages.perPage || (20 as number)
        const isActivated = !filters.hasAllOrganization || null
        const keyword = filters.searchField || (null as null | string)
        const params = {
            offset,
            limit,
            activated: isActivated,
            keyword
        }
        return new Promise((resolve, reject) => {
            const url = '/cloudapi/organizations'
            ;(this as any).$axios
                .$get(url, { params })
                .then((res: any) => {
                    const organizations = (this as any).$toCamel(res)
                    resolve(organizations)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    createOrganization(
        this: any,
        _vuexContext: any,
        params: {
            organization: Organization | null
            shouldSetOrganization: boolean
        } = {
            organization: null,
            shouldSetOrganization: true
        }
    ) {
        return new Promise((resolve, reject) => {
            const { organization } = params

            const organizationFormatted: OrganizationJSON = (
                this as any
            ).$toSnake(organization)
            delete organizationFormatted.id
            delete organizationFormatted.bu_count
            if (organizationFormatted.main_contact.phone == null) {
                organizationFormatted.main_contact.phone = ''
            }
            if (organizationFormatted.main_contact.id === 0) {
                delete organizationFormatted.main_contact.id
            }
            ;(this as any).$axios
                .$post('/cloudapi/organizations', organizationFormatted)
                .then((res: any) =>
                    this.$auth
                        .refreshTokens()
                        .then(() => resolve(res))
                        .catch((e: any) => {
                            reject(e)
                        })
                )
                .catch((e: any) => {
                    reject(e.response)
                })
        })
    },
    getOrganization(_vuexContext: any, id: number | undefined) {
        if (id === undefined) {
            return Promise.reject(new Error('No organization'))
        }
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$get(`/cloudapi/organizations/${id}`)
                .then((res: any) => {
                    const organization = (this as any).$toCamel(res)
                    resolve(organization)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    updateOrganization(
        vuexContext: any,
        params: {
            organization: Organization | null
            shouldSetOrganization: boolean
        } = {
            organization: null,
            shouldSetOrganization: true
        }
    ) {
        return new Promise((resolve, reject) => {
            const { organization, shouldSetOrganization } = params
            if (!organization) return

            const organizationFormatted: OrganizationJSON = (
                this as any
            ).$toSnake(organization)
            delete organizationFormatted.id
            delete organizationFormatted.bu_count
            if (organizationFormatted.main_contact.phone == null) {
                organizationFormatted.main_contact.phone = ''
            }
            if (organizationFormatted.main_contact.id === 0) {
                delete organizationFormatted.main_contact.id
            }
            ;(this as any).$axios
                .$patch(
                    `/cloudapi/organizations/${organization.id}`,
                    organizationFormatted
                )
                .then((res: any) => {
                    const orga = (this as any).$toCamel(res)
                    if (shouldSetOrganization) {
                        vuexContext.dispatch('setOrganization', orga)
                    }
                    resolve(orga)
                })
                .catch((e: any) => {
                    reject(e.response)
                })
        })
    },
    fetchOrganizationsFromPipedrive(_: any, pipeline: Pipeline) {
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$get(`/cloudapi/pipedrive/pipelines/${pipeline}/deals`)
                .then((res: any) => {
                    resolve((this as any).$toCamel(res))
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    fetchOrganizationFromPipedrive(
        _: any,
        payload: { organizationId: number; personId: number }
    ) {
        const { organizationId, personId } = payload
        const params = {
            person_id: personId
        }
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$get(`/cloudapi/pipedrive/organizations/${organizationId}`, {
                    params
                })
                .then((res: any) => {
                    resolve((this as any).$toCamel(res))
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }
}

export default {
    namespaced: true as true,
    state,
    getters,
    mutations,
    actions
}

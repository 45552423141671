import { createLocalVue, shallowMount } from '@vue/test-utils'

// @ts-ignore
import ChargemapDialog from '@/components/UI/CMUI/ChargemapDialog.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'

const props = {
    variant: 'delete'
}

const localVue = createLocalVue()
localVue.directive('click-outside', jest.fn())

describe('ChargemapDialog', () => {
    let wrapper: any
    beforeEach(() => {
        wrapper = shallowMount(ChargemapDialog, {
            ...basicMountWrapper,
            localVue,
            propsData: props
        })
    })

    it('is a Vue instance', async () => {
        expect(wrapper.vm).toBeTruthy()
        let component = wrapper.find('[data-testid="chargemap-dialog"]')
        expect(component.exists()).toBeFalsy()

        await wrapper.vm.updateModalVisibility(true)
        component = wrapper.find('[data-testid="chargemap-dialog"]')
        expect(component.exists()).toBeTruthy()
    })

    it('can return colorBtn', () => {
        expect(wrapper.vm.colorBtn).toBe('alert-full')
    })

    it('can close', () => {
        wrapper.vm.resolve = jest.fn().mockResolvedValue(true)
        const spyMethod = jest.spyOn(wrapper.vm, 'updateModalVisibility')
        const spyResolve = jest.spyOn(wrapper.vm, 'resolve')
        wrapper.vm.close()
        expect(spyMethod).toHaveBeenCalledWith(false)
        expect(spyResolve).toHaveBeenCalledWith(false)
    })

    it('can confirm', () => {
        wrapper.vm.resolve = jest.fn().mockResolvedValue(true)
        const spyMethod = jest.spyOn(wrapper.vm, 'updateModalVisibility')
        const spyResolve = jest.spyOn(wrapper.vm, 'resolve')
        wrapper.vm.confirm()
        expect(spyMethod).toHaveBeenCalledWith(false)
        expect(spyResolve).toHaveBeenCalledWith(true)
    })
})

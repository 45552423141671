
import { mapGetters } from 'vuex'
import IconClose from '~/components/UI/icons/notification/IconClose.vue'

export default {
    name: 'AppBottomSheet',
    components: { IconClose },
    computed: {
        ...mapGetters({
            filters: 'mobileFilter/getFilters',
            showBottomSheet: 'mobileFilter/getShowBottomSheet'
        })
    },
    beforeDestroy() {
        this.resetFilters()
    },
    methods: {
        resetFilters(newFilters) {
            this.$store.commit('mobileFilter/setFilters', newFilters || [])
        },
        addFilter(newFilter) {
            this.$store.commit('mobileFilter/addFilter', newFilter)
        },
        removeFilter(filterId) {
            this.$store.commit('mobileFilter/removeFilter', filterId)
        },
        update(filter) {
            this.$store.commit('mobileFilter/updateFilter', filter)
        },
        reset() {
            this.$store.commit('mobileFilter/resetFilter')
            this.$store.commit('mobileFilter/setToUpdate', true)
        },
        close() {
            this.$store.commit('mobileFilter/setToUpdate', true)
            this.$store.commit(
                'mobileFilter/setShowBottomSheet',
                !this.showBottomSheet
            )
        }
    }
}

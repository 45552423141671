
import Vue from 'vue'

export default Vue.extend({
    name: 'AppCheckboxInput',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: ''
        },
        labelKey: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onChange(event: { target: HTMLInputElement }) {
            this.$emit('change', event.target.checked)
        }
    }
})
